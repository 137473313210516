





































































































































import { Component, Watch, Vue } from 'vue-property-decorator';
import PageContainer from '@/components/PageContainer.vue';
import PageTitle from '@/components/PageTitle.vue';
import PageBreadcrumbs from '@/components/PageBreadcrumbs.vue';
import { Action } from 'vuex-class';
import { IEventsSingle, IEventStatus } from '@/types/events';
import { Route } from 'vue-router';
import { eventSingle, courseClosestEvent } from '@/service/api/module/events';
import PageTabs from '@/components/PageTabs.vue';
import { IPageTab } from '@/types/page';
import EventsCardSidebar from '@/components/EventsCardSidebar.vue';
import { eventDatetime } from '@/helpers/eventDatetime';
import { capitalize } from '@/helpers/capitalize';
import { IButtonType, IButtonSize } from '@/types/buttons';
import Button from '@/components/Button.vue';
import { coursesSingle, coursesCategorySingle } from '@/service/api/module/course';
import { ICourseSingle, ICoursesCategoriesSingle } from '@/types/courses';
import { permalink } from '@/helpers/permalink';
import Modal from '@/components/Modal.vue';
import EventsRegister from '@/components/EventsRegister.vue';
import Loading from '@/components/Loading.vue';

@Component({
  components: {
    PageContainer,
    PageBreadcrumbs,
    PageTitle,
    PageTabs,
    EventsCardSidebar,
    Button,
    Modal,
    EventsRegister,
    Loading,
  },
})
export default class ScheduleSingle extends Vue {
  @Action('BodyBackground/updateGrey') updateGrey!: any;

  @Watch('$route', { immediate: true, deep: true })
  private async onChanged(route: Route) {
    const eventId = Number(route.params.id);
    if (!eventId) {
      return this.$router.push({ name: 'Home' });
    }
    this.getEvent({ id: eventId }).then(() => {
      this.getOthersEvents();
      this.getCourse().then(() => this.getCategory());
      this.isLoading = false;
    });
  }

  public event: IEventsSingle | null = null;
  private eventId!: number;
  private courseId!: number;
  private course: ICourseSingle | null = null;
  private othersEvents: IEventsSingle[] | null = null;
  private othersEventsLimit = 3;
  private category: ICoursesCategoriesSingle | null = null;
  private categoryId?: number;
  private categorySlug?: string;

  private eventDatetime = eventDatetime;
  private capitalize = capitalize;
  private IButtonType = IButtonType;
  private IButtonSize = IButtonSize;
  private IEventStatus = IEventStatus;
  public isLoading = true;

  private created(): void {
    this.updateGrey({ status: true });
  }

  private destroyed(): void {
    this.updateGrey({ status: false });
  }

  private async getEvent({ id }: { id: number }): Promise<void | Route> {
    this.eventId = id;
    this.event = await eventSingle({ id: this.eventId });

    if (!this.event) {
      return this.$router.push({ name: 'Home' });
    }

    this.courseId = this.event.course.id;

    // validating for lang
    const { params } = this.$route;
    const { meta } = this.event;
    if (!params || Number(params.id) !== Number(this.event.id)) {
      return this.$router.replace({ params: { id: this.event.id.toString(), slug: meta.slug } });
    }
  }

  private async getOthersEvents(): Promise<void> {
    const othersEvents = await courseClosestEvent({ id: this.courseId });
    if (othersEvents && othersEvents.length) {
      this.othersEvents = othersEvents.filter((event: IEventsSingle) => event.id !== this.eventId).slice(0, this.othersEventsLimit);
    }
  }

  private async getCourse() {
    const course = await coursesSingle({ id: this.courseId });
    this.course = course.items[0];
  }

  private async getCategory() {
    if (!this.course) {
      return;
    }
    this.category = await coursesCategorySingle({ id: this.course.categories[0].id, locale: this.$route.params.lang });
    this.categoryId = this.category?.id;
    this.categorySlug = permalink({ text: this.category?.name });
  }

  private get tabsContent(): IPageTab[] | null {
    if (!this.event) {
      return null;
    }

    const tabs = [];

    if (this.event.what_you_need_to_know) {
      tabs.push({
        name: this.$t('components.page.tabs.menu.whatYouNeedToKnow').toString(),
        content: this.event.what_you_need_to_know,
      });
    }

    return tabs;
  }
}
