












































import { Component, Prop, Vue } from 'vue-property-decorator';
import Carousel from '@/components/Carousel.vue';
import { Slide } from 'vue-carousel';
import { IHomepageTestimonial } from '@/types/homepage';

@Component({
  components: {
    Carousel,
    Slide,
  },
})
export default class HomepageTestimonial extends Vue {
  @Prop({ required: true, default: null }) testimonials?: IHomepageTestimonial[];
}
