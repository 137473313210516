import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
class SidenavExtend extends VuexModule {
  private open = false;

  @Mutation toggleSidenav(): void {
    this.open = !this.open;
  }

  @Mutation closeSidenav(): void {
    this.open = false;
  }

  @Action toggle(): void {
    this.context.commit('toggleSidenav');
  }

  @Action close(): void {
    this.context.commit('closeSidenav');
  }

}
export { SidenavExtend };
