




































import { Component, Prop, Vue } from 'vue-property-decorator';
import NewsPostCard from '@/components/NewsPostCard.vue';
import { INewsPost } from '@/types/news';

@Component({
  components: {
    NewsPostCard,
  },
})
export default class HomepageNews extends Vue {
  @Prop({ required: true, default: null }) posts!: INewsPost[] | null;
}
