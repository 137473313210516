

































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Modal as AModal } from 'ant-design-vue';
import { IEmitRoot } from '@/types/emit';

@Component({
  components: {
    AModal,
  },
})
export default class Modal extends Vue {
  @Prop({ required: false, default: null }) title!: string;
  @Prop({ required: false, default: true }) withContentDot?: boolean;

  private modalVisible = false;

  private beforeCreate() {
    this.$root.$on(IEmitRoot.MODAL_CLOSE, () => (this.modalVisible = false));
  }
}
