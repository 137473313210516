import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
class BodyBackground extends VuexModule {
  private isGrey = false;

  public get getGrey(): boolean {
    return this.isGrey;
  }

  @Mutation setGrey({ status }: { status: boolean }): void {
    this.isGrey = status;
  }

  @Action updateGrey({ status }: { status: boolean }): void {
    this.context.commit('setGrey', { status });
  }
}
export { BodyBackground };
