












import { Component, Vue } from 'vue-property-decorator';
import { BackTop as ABackTop } from 'ant-design-vue';

@Component({
  components: {
    ABackTop,
  },
})
export default class BackTop extends Vue {}
