import axios from '../axios';
import { AxiosResponse, AxiosError } from 'axios';
import { IFormContact, IFormEventRegister } from '@/types/forms';

const contactUri = 'api/contact/form/';
const eventRegisterUri = 'api/event/register/';

const formContact = (fields: IFormContact): Promise<any> => {
  return axios.post(contactUri, fields)
};

const formEventRegister = (fields: IFormEventRegister): Promise<any> => {
  return axios.post(eventRegisterUri, fields)

};

export { formContact, formEventRegister };
