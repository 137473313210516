import i18n from '@/locales';

export const courseDuration = ({ duration }: { duration: string }) => {
  if (!duration) {
    return '';
  }
  const [days] = duration.split(' ');
  if (Number(days) > 1) {
    return `${days} ${i18n.t('components.coursesCard.durationDays').toString()}`;
  }
  return `${days} ${i18n.t('components.coursesCard.durationDay').toString()}`;
};
