















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { capitalize } from '@/helpers/capitalize';
import Button from '@/components/Button.vue';
import { IButtonType, IButtonSize, IButtonColor } from '@/types/buttons';
import { ICourseConcludedWith } from '@/types/courses';
import { courseDuration } from '@/helpers/courseDuration';
import AnimatedNumber from 'animated-number-vue';

@Component({
  components: {
    Button,
    AnimatedNumber,
  },
})
export default class CoursesOverviewSidebar extends Vue {
  @Prop({ required: false, default: false }) duration!: boolean;
  @Prop({ required: true, default: null }) concludedWith!: string;
  @Prop({ required: false, default: null }) successRate?: string;
  @Prop({ required: false, default: null }) brochure?: string | null;

  private capitalize = capitalize;
  private IButtonType = IButtonType;
  private IButtonSize = IButtonSize;
  private IButtonColor = IButtonColor;
  private courseDuration = courseDuration;

  public exam = '';
  public certification = '';

  private created() {
    if (this.concludedWith) {
      this.concluded();
    }
  }

  private successRateFormat(value: number) {
    return `${this.$t('components.coursesOverview.successRate', { value: Number(+value).toFixed(2) })}`;
  }

  private concluded() {
    if (this.concludedWith === ICourseConcludedWith.EXAM_CERTIFICATION) {
      this.exam = this.$t('components.CoursesOverviewSidebar.included').toString();
      this.certification = this.$t('components.CoursesOverviewSidebar.avaliable').toString();
    } else if (this.concludedWith === ICourseConcludedWith.NO_EXAM_NO_CERTIFICATION) {
      this.exam = this.$t('components.CoursesOverviewSidebar.notIncluded').toString();
      this.certification = this.$t('components.CoursesOverviewSidebar.notAvaliable').toString();
    } else if (this.concludedWith === ICourseConcludedWith.CERTIFICATION) {
      this.exam = this.$t('components.CoursesOverviewSidebar.notIncluded').toString();
      this.certification = this.$t('components.CoursesOverviewSidebar.avaliable').toString();
    }
  }
}
