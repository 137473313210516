import axios, { AxiosResponse, AxiosError } from 'axios';
import store from '@/store';

axios.defaults.headers.common = { 'Content-Type': 'application/json' };
axios.defaults.headers.common['Accept-Language'] = store.getters['Languages/getCurrent'];
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.timeout = 15000;

axios.interceptors.request.use(
  config => config,
  error => Promise.reject(error),
);

axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => Promise.reject(error),
);

export default axios;
