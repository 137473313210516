











import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class PageDescription extends Vue {
  @Prop({ required: false, default: null }) description!: string;
}
