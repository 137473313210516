/* eslint-disable @typescript-eslint/camelcase */

import axios from '../axios';
import { AxiosResponse, AxiosError } from 'axios';
import { ITrainers, ITrainer, IWhoWeAre, IWhoWeAres } from '@/types/academy';
import store from '@/store';
import {IAcademyTestimonialPages, ITestimonialPage} from "@/types/testimonials";

const TRAINER_URI = `api/v2/trainer/?locale=${store.getters['Languages/getCurrent']}`;
const WHOWEARE_URI = `api/v2/pages/?type=academy.WhoWeAreIndexPage&fields=*&locale=${store.getters['Languages/getCurrent']}`;
const URI = `api/v2/pages/`;

const trainers = async (): Promise<ITrainer[] | null> => {
  return await axios
    .get(TRAINER_URI)
    .then((response: AxiosResponse) => {
      const { items }: ITrainers = response.data;
      return items;
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return null;
    });
};

const whoWeAre = async (): Promise<IWhoWeAre | null> => {
  return await axios
    .get(WHOWEARE_URI)
    .then((response: AxiosResponse) => {
      const { items }: IWhoWeAres = response.data;
      return items[0];
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return null;
    });
};

const academyTestimonials = async (lang: string): Promise<ITestimonialPage | null> => {
  return await axios
    .get(URI, {params: {
      type: 'academy.TestimonialsPage',
      fields: '*',
      locale: lang
    }})
    .then((response: AxiosResponse) => {
      const { items }: IAcademyTestimonialPages = response.data;
      return items[0];
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return null;
    });
};

export { trainers, whoWeAre, academyTestimonials };
