

































import { Component, Prop, Vue } from 'vue-property-decorator';
import { IPageTab } from '@/types/page';
import FeedbackCarousel from '@/components/FeedbackCarousel.vue';

@Component({
  components: {
    FeedbackCarousel,
  }
})
export default class PageTabs extends Vue {
  @Prop({ required: true, default: null }) tabs!: IPageTab[];

  private activeTabsContent = 0;

  private isActiveTab(contentItem: number): boolean {
    return this.activeTabsContent === contentItem;
  }

  private setActiveTab(contentItem: number): void {
    this.activeTabsContent = contentItem;
  }
}
