



































































































































































import { Component, Vue } from 'vue-property-decorator';
import HomepageSearch from '@/components/HomepageSearch.vue';
import HomepageCountsCard from '@/components/HomepageCountsCard.vue';
import { IHomepageField, IHomepageFlayer, IHomepageBestSeller, IHomepageTestimonial, IHomepagePartner } from '@/types/homepage';
import { INewsPost } from '@/types/news';
import { homepageItems, homepageFlayers, homepageBestSellers, homepageTestimonials, homepagePartners } from '@/service/api/module/homepage';
import { coursesCategoryBackground as coursesCategoryBackgroundFetch } from '@/service/api/module/course';
import { ICoursesCategoriesBackground } from '@/types/courses';
import { newsPosts as posts } from '@/service/api/module/news';
import HomepageFlayer from '@/components/HomepageFlayer.vue';
import { ICoursesCategoriesSingle } from '@/types/courses';
import { coursesCategory } from '@/service/api/module/course';
import CoursesCategoryCard from '@/components/CoursesCategoryCard.vue';
import { permalink } from '@/helpers/permalink';
import HomepagePartnership from '@/components/HomepagePartnership.vue';
import HomepageTestimonial from '@/components/HomepageTestimonial.vue';
import HomepageExpirience from '@/components/HomepageExpirience.vue';
import HomepagePartners from '@/components/HomepagePartners.vue';
import HomepageNews from '@/components/HomepageNews.vue';
import { MetaInfo } from 'vue-meta';
import Loading from '@/components/Loading.vue';
import { Action } from 'vuex-class';

@Component({
  metaInfo(this: Vue & { $t: string }): MetaInfo {
    return {
      title: this.$t('views.homepage.titleSeo').toString(),
    };
  },
  components: {
    HomepageSearch,
    HomepageCountsCard,
    HomepageFlayer,
    CoursesCategoryCard,
    HomepagePartnership,
    HomepageTestimonial,
    HomepagePartners,
    HomepageNews,
    HomepageExpirience,
    Loading,
  },
})
export default class HomepageHome extends Vue {
  @Action('Thumbnail/updateImage') updateThumbnailImage!: any;

  public permalink = permalink;
  public isLoading = true;

  public fileds?: IHomepageField | null = null;
  public flayers?: IHomepageFlayer[] | null = null;
  public bestSellers?: IHomepageBestSeller[] | null = null;
  public courseCategories: ICoursesCategoriesSingle[] | null = null;
  public testimonials: IHomepageTestimonial[] | null = null;
  public partners: IHomepagePartner[] | null = null;
  public blogPosts: INewsPost[] | null = null;
  public coursesCategoryBackground: ICoursesCategoriesBackground | null = null;

  private async created(): Promise<void> {
    const [
      fileds,
      flayers,
      bestSellers,
      courseCategories,
      testimonials,
      partners,
      blogPosts,
      coursesCategoryBackground,
    ] = await Promise.all([
      homepageItems(),
      homepageFlayers(),
      homepageBestSellers(),
      coursesCategory(this.$route.params.lang),
      homepageTestimonials(),
      homepagePartners(),
      posts(),
      coursesCategoryBackgroundFetch(),
    ]);
    this.fileds = fileds;
    this.flayers = flayers;
    this.bestSellers = bestSellers && bestSellers?.length > 3 ? bestSellers.slice(0, 3) : bestSellers;
    this.courseCategories = courseCategories && courseCategories?.length > 3 ? courseCategories.slice(0, 6) : courseCategories;
    this.testimonials = testimonials;
    this.partners = partners;
    this.blogPosts = blogPosts && blogPosts.items.length > 3 ? blogPosts.items.slice(0, 3) : blogPosts?.items ?? null;
    this.coursesCategoryBackground = coursesCategoryBackground;
    this.isLoading = false;

    if (this.fileds) {
      this.updateThumbnailImage({ url: this.fileds.home_thumbnail });
    }
  }

  public get counts(): null | { icon: string; name: string; content: string }[] {
    if (!this.fileds) {
      return null;
    }

    return [
      {
        icon: require('@/assets/images/icons/counts-courses.svg'),
        name: this.$t('components.homepage.counts.courses', { value: this.fileds.number_of_courses }).toString(),
        content: this.fileds.courses_description,
      },
      {
        icon: require('@/assets/images/icons/counts-trainers.svg'),
        name: this.$t('components.homepage.counts.trainers', { value: this.fileds.number_of_participants }).toString(),
        content: this.fileds.trainers_description,
      },
      {
        icon: require('@/assets/images/icons/counts-rate.svg'),
        name: this.$t('components.homepage.counts.successRate', { value: this.fileds.success_rate }).toString(),
        content: this.fileds.success_rate_description,
      },
    ];
  }
}
