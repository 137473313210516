import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { IEventsFilters } from '@/types/events';

@Module({ namespaced: true })
class Filters extends VuexModule {
  private current: IEventsFilters | null = null;

  public get getCurrent(): IEventsFilters | null {
    return this.current;
  }

  @Mutation setFilters({ filters }: { filters: IEventsFilters }): void {
    this.current = filters;
  }

  @Action updateFilters({ filters }: { filters: IEventsFilters }): void {
    this.context.commit('setFilters', { filters });
  }
}
export { Filters };
