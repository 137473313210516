



































import { Component, Prop, Vue } from 'vue-property-decorator';

import Button from '@/components/Button.vue';
import SearchBar from '@/components/SearchBar.vue';
import { IButtonType, IButtonSize } from '@/types/buttons';

@Component({
  components: {
    Button,
    SearchBar,
  },
})
export default class HomepageSearch extends Vue {
  @Prop({ required: true, default: null }) title!: string;
  @Prop({ required: false, default: null }) image?: string;

  public IButtonType = IButtonType;
  public IButtonSize = IButtonSize;
  public searchText: string | null = null;

  public onSearch() {
    if (!this.searchText) {
      return;
    }
    this.$router.push({ name: 'Search', query: { search: this.searchText } });
  }
}
