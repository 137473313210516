import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { BodyBackground } from './module/BodyBackground';
import { Languages } from './module/Languages';
import { SidenavExtend } from './module/SidenavExtend';
import { Filters } from './module/Filters';
import { Thumbnail } from './module/Thumbnail';

Vue.use(Vuex);

const store = new Store({
  modules: { BodyBackground, Languages, SidenavExtend, Filters, Thumbnail },
  plugins: [
    createPersistedState({
      key: 'aa',
      paths: ['Languages'],
      storage: window.localStorage,
    }),
  ],
});

export default store;
