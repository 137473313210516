






















import {Component, Prop, Vue} from "vue-property-decorator";
import {ITestimonial} from "@/types/testimonials";

@Component({})
export default class Testimonial extends Vue {
  @Prop({ required: true, default: null }) testimonial!:  ITestimonial;
}
