













import { Component, Prop, Vue } from 'vue-property-decorator';
import { capitalize } from '@/helpers/capitalize';

@Component({})
export default class PageTags extends Vue {
  @Prop({ required: false, default: null }) tags!: string[];

  private capitalize = capitalize;
}
