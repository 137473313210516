import axios from '../axios';
import { AxiosResponse, AxiosError } from 'axios';
import { IVenues, IVenue } from '@/types/venue';
import store from '@/store';

const VENUE_URI = `api/v2/pages/?type=academy.VenueIndexPage&fields=*&locale=${store.getters['Languages/getCurrent']}`;

const venue = async (): Promise<IVenue | null> => {
  return await axios
    .get(VENUE_URI)
    .then((response: AxiosResponse) => {
      const { items }: IVenues = response.data;
      return items[0];
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return null;
    });
};

export { venue };
