import i18n from '@/locales';
import { IVueMetadata, IVueMetadataLink } from '@/types/vueMeta';

const defaultTitle = (str?: string): string => {
  if (!str) {
    return i18n.t('global.domain').toString();
  }
  return `${str} • ${i18n.t('global.domain').toString()}`;
};

export const metaTitles = (str?: string): IVueMetadata[] => {
  return [
    { vmid: 'title', name: 'title', content: defaultTitle(str) },
    { vmid: 'twitter:title', name: 'twitter:title', content: defaultTitle(str) },
    { vmid: 'og:title', property: 'og:title', content: defaultTitle(str) },
  ];
};

export const metaDescriptions = (str?: string): IVueMetadata[] => {
  const description: string = i18n.t('global.metadata.description', { siteName: defaultTitle() }).toString();
  const content = str ? str : description;
  return [
    { vmid: 'description', name: 'description', content },
    { vmid: 'twitter:description', name: 'twitter:description', content },
    { vmid: 'og:description', property: 'og:description', content },
  ];
};

export const metaKeywords = (str?: string): IVueMetadata[] => {
  const keywords: string = i18n.t('global.metadata.keywords').toString();
  return [{ vmid: 'keywords', name: 'keywords', content: str ? str : keywords }];
};

// @todo
export const metaImage = (str?: string): IVueMetadata[] => {
  const image = '';
  const content = str ? str : image;
  return [
    { vmid: 'og:image', property: 'og:image', content },
    { vmid: 'twitter:image', name: 'twitter:image', content },
  ];
};

export const metaLink = (): IVueMetadataLink[] => {
  const location: string = document.location.href;
  return [{ rel: 'canonical', href: location }];
};

export const metaStatic = (): IVueMetadata[] => {
  return [
    { vmid: 'application-name', name: 'application-name', content: defaultTitle() },
    { vmid: 'robots', name: 'robots', content: 'index, follow, all, noarchive' },
    { vmid: 'googlebot', name: 'googlebot', content: 'noodp' },
    { vmid: 'og:site_name', property: 'og:site_name', content: defaultTitle() },
    { vmid: 'og:type', property: 'og:type', content: 'website' },
    { vmid: 'og:ttl', property: 'og:ttl', content: '600' },
    { vmid: 'og:url', property: 'og:url', content: document.location.href },
    { vmid: 'twitter:site', name: 'twitter:site', content: defaultTitle() },
    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
  ];
};
