















import { Component, Prop, Vue } from 'vue-property-decorator';
import { trimText } from '@/helpers/trimText';

@Component({})
export default class CoursesCategoryCard extends Vue {
  @Prop({ required: true, default: null }) name!: string;
  @Prop({ required: true, default: null }) description!: string;
  @Prop({ required: true, default: null }) slug!: string;
  @Prop({ required: true, default: null }) id!: number;

  private trimText = trimText;
}
