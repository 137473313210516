import axios from '../axios';
import { AxiosResponse, AxiosError } from 'axios';
import { IEvents, IEventsSingle, IEventsFilters } from '@/types/events';
import { IFiltersTypesQuery } from '@/types/filters';
import { removeEmptyParams } from '@/helpers/removeEmptyParams';
import store from '@/store';

const EVENTS_PAGE_URI = `api/v2/pages/?type=event.EventPage&fields=*&filters=true&locale=${store.getters['Languages/getCurrent']}`;
const EVENTS_FILTERS_URI = `api/event/filters/?locale=${store.getters['Languages/getCurrent']}&filters=true`;

const courseClosestEvent = async ({ id, customOrder }: { id: number; customOrder?: boolean }): Promise<IEventsSingle[] | null> => {
  let uri = `${EVENTS_PAGE_URI}&course=${id}&locale=${store.getters['Languages/getCurrent']}`;
  if (customOrder) {
    uri = `${uri}&order_events=status,start_date`;
  }
  return await axios
    .get(uri)
    .then((response: AxiosResponse) => {
      const { items }: IEvents = response.data;
      return items;
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return null;
    });
};

const eventFilters = async (): Promise<IEventsFilters | null> => {
  return await axios
    .get(EVENTS_FILTERS_URI)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return null;
    });
};

const eventsByFilters = async ({ params, customOrder }: { params: IFiltersTypesQuery; customOrder?: boolean }): Promise<any> => {
  let query = `&event_category=${params.category}&event_course=${params.course}&event_language=${params.language}&event_location=${params.location}&event_month=${params.datetime}&event_training_format=${params.training_formats}`;
  if (customOrder) {
    query = `${query}&order_events=status,start_date`;
  }

  return await axios
    .get(`${EVENTS_PAGE_URI}${removeEmptyParams(query)}`)
    .then((response: AxiosResponse) => {
      const { items, filters }: any = response.data;
      return {
        events: items,
        filters,
      };
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return null;
    });
};

const events = async (offset?: number): Promise<IEventsSingle[] | any | null> => {
  const uri = offset ? `${EVENTS_PAGE_URI}&offset=${offset}` : EVENTS_PAGE_URI;
  return await axios
    .get(uri)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return null;
    });
};

const eventSingle = async ({ id }: { id: number }): Promise<IEventsSingle | any | null> => {
  return await axios
    .get(`${EVENTS_PAGE_URI}&id=${id}&locale=${store.getters['Languages/getCurrent']}`)
    .then((response: AxiosResponse) => {
      const { items } = response.data;
      return items && items[0] ? items[0] : null;
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return null;
    });
};

export { courseClosestEvent, eventFilters, eventsByFilters, events, eventSingle };
