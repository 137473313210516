



































































































































import { Component, Vue } from 'vue-property-decorator';
import PageBreadcrumbs from '@/components/PageBreadcrumbs.vue';
import PageContainer from '@/components/PageContainer.vue';
import PageTitle from '@/components/PageTitle.vue';
import PageDescription from '@/components/PageDescription.vue';
import CardsWithIcons from '@/components/CardsWithIcons.vue';
import { trainers as trainersFetch, whoWeAre as whoWeAreFetch } from '@/service/api/module/academy';
import { abelineExpirience as abelineExpirienceFetch } from '@/service/api/module/expirience';
import { IAbelineExpirience, IExpirience } from '@/types/expirience';
import Loading from '@/components/Loading.vue';
import AppCard from "@/components/AppCard.vue"

@Component({
  metaInfo(this: Vue & { $t: string; expiriencePage: IExpirience }): any {
    return {
      title: this.expiriencePage && this.expiriencePage.title ? this.expiriencePage.title : '',
    };
  },
  components: {
    PageContainer,
    PageBreadcrumbs,
    PageTitle,
    PageDescription,
    CardsWithIcons,
    Loading,
    AppCard
  },
})
export default class AbelineExpirience extends Vue {
  public expiriencePage?: IExpirience | null = null;
  public isLoading = true;
  public benefits = [
    {title: 'Instructor-led sessions, in-class and/or online', icon: 'user-check'},
    {title: 'Whiteboard drawings', icon: 'file-edit'},
    {title: 'Virtual breakout rooms', icon: 'meeting-board'},
    {title: 'Chats', icon: 'chat'},
    {title: 'Polling', icon: 'browser'},
  ];

  private async created() {
    const [expiriencePage] = await Promise.all([ abelineExpirienceFetch(this.$route.params.lang)]);

    this.isLoading = false;
    this.expiriencePage = expiriencePage;

    // setTimeout(() => this.masonary(), 1000);
  }

  // private masonary() {
  //   const grid = this.$refs.masonryColumns as any;
  //   new Masonry(grid, {
  //     itemSelector: '.grid-item',
  //     gutter: 30,
  //   });
  // }

  public get cardIcons() {
    return [
      {
        icon: require('@/assets/images/icons/shield-silver.svg'),
        name: this.$t('views.academy.whoWeAre.cardsIcons.practical.title').toString(),
        content: this.$t('views.academy.whoWeAre.cardsIcons.practical.content').toString(),
      },
      {
        icon: require('@/assets/images/icons/diff-silver.svg'),
        name: this.$t('views.academy.whoWeAre.cardsIcons.adapted.title').toString(),
        content: this.$t('views.academy.whoWeAre.cardsIcons.adapted.content').toString(),
      },
      {
        icon: require('@/assets/images/icons/sketch-silver.svg'),
        name: this.$t('views.academy.whoWeAre.cardsIcons.satisfaction.title').toString(),
        content: this.$t('views.academy.whoWeAre.cardsIcons.satisfaction.content').toString(),
      },
    ];
  }
}
