
































































































































import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ILanguage } from '@/types/language';
import HamburgerButton from '@/components/HamburgerButton.vue';
import SearchButton from '@/components/SearchButton.vue';

@Component({
  components: {
    HamburgerButton,
    SearchButton,
  },
})
export default class HeaderMenu extends Vue {
  @Action('Languages/updateLanguage') updateLanguage!: any;
  @Getter('Languages/getCurrent') languageActive!: ILanguage;
  @Action('SidenavExtend/toggle') toggleSidenav!: typeof Boolean;

  private language = [ILanguage.ENGLISH.toString(), ILanguage.FRENCH.toString()];

  private switchLanguage(e: any) {
    const lang = this.language[e.key];
    this.updateLanguage({ lang });
    const test = this.$router.resolve({ params: { lang } }).href
    console.log(test)
    this.$router.replace({ params: { lang } });
    
    return this.$router.go(0);
  }
  private SidenavLanguage() {
    this.toggleSidenav();
  }
}
