








import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class SearchButton extends Vue {
@Prop({required: false, default: false}) hideOnMobile?: boolean
}
