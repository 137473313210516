import { IEventsSingle } from '@/types/events';
import { capitalize } from '@/helpers/capitalize';
import { monthCapitalize } from '@/helpers/dateFns';

export const eventDatetime = ({ event, inline = false }: { event: IEventsSingle | any; inline?: boolean }): string | null => {
  if (!event || !event.start_date || !event.end_date) {
    return null;
  }

  const startDate = event.start_date.toString().split('-');
  const endDate = event.end_date.toString().split('-');

  const startMonth = startDate[1].toString();
  const startDay = startDate[2].toString();
  const endMonth = endDate[1].toString();
  const endDay = endDate[2].toString();

  if (startMonth === endMonth) {
    if (startDay === endDay) {
      return `${startDay} ${capitalize({ text: monthCapitalize({ date: event.start_date }) })}`;
    }
    return `${startDay}-${endDay} ${capitalize({ text: monthCapitalize({ date: event.end_date }) })}`;
  }

  return `${startDay} ${capitalize({ text: monthCapitalize({ date: event.start_date }) })} - ${inline ? '' : '</br>'}${endDay} ${capitalize(
    {
      text: monthCapitalize({ date: event.end_date }),
    },
  )}`;
};

export const eventDatetimeHomepage = ({ event }: { event: IEventsSingle }): string | null => {
  if (!event || !event.start_date || !event.end_date) {
    return null;
  }

  const startDate = event.start_date;
  const endDate = event.end_date;

  const [startDateYear, startDateMonth, startDateDay] = startDate.toString().split('-');
  const [endDateYear, endDateMonth, endDateDay] = endDate.toString().split('-');

  if (startDateMonth === endDateMonth) {
    return `${startDateDay}-${endDateDay} ${capitalize({ text: monthCapitalize({ date: startDate }) })} ${endDateYear}`;
  }

  const start = `${startDateDay} ${capitalize({ text: monthCapitalize({ date: startDate }) })}`;
  const end = `${endDateDay} ${capitalize({ text: monthCapitalize({ date: endDate }) })}`;

  return `${start} - ${end} ${startDateYear || endDateYear}`;
};
