











import { Component, Prop, Vue } from 'vue-property-decorator';
import { IButtonType, IButtonSize, IButtonColor } from '@/types/buttons';

@Component({})
export default class Button extends Vue {
  @Prop({ required: false, default: IButtonType.PRIMARY }) type?: IButtonType;
  @Prop({ required: false, default: IButtonSize.MEDIUM }) size?: IButtonSize;
  @Prop({ required: false, default: IButtonColor.DEFAULT }) color?: IButtonColor;
  @Prop({ required: false, default: false }) fullWidth?: boolean;
  @Prop({ required: true, default: null }) title!: string;
  @Prop({ required: false, default: false }) rounded!: boolean;
  @Prop({ required: false, default: '' }) fontSize!: string;

  private get switchType(): string {
    switch (this.type) {
      case IButtonType.PRIMARY:
        return 'btn__primary';
      case IButtonType.SECONDARY:
        return 'btn__secondary';
      default:
        return 'btn__primary';
    }
  }

  private get switchSize(): string {
    switch (this.size) {
      case IButtonSize.MEDIUM:
        return 'btn--medium';
      case IButtonSize.LARGE:
        return 'btn--large';
      default:
        return '';
    }
  }

  private get switchColor(): string {
    if (this.type === IButtonType.PRIMARY) {
      switch (this.color) {
        case IButtonColor.TRANSPARENT:
          return 'btn__primary--transparent';
        case IButtonColor.WHITE:
          return 'btn__primary--white';
        default:
          return '';
      }
    } else {
      switch (this.color) {
        case IButtonColor.TRANSPARENT:
          return 'btn__secondary--transparent';
        case IButtonColor.WHITE:
          return 'btn__secondary--white';
        default:
          return '';
      }
    }
  }

  private get btnRounded() {
    if(this.rounded) return 'btn--rounded'
    return ''
  }
}
