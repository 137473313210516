import { RouteConfig } from 'vue-router';
import CoursesHome from '@/views/courses/Home.vue';
import CoursesCategory from '@/views/courses/Category.vue';
import CoursesSingle from '@/views/courses/Single.vue';

export const CoursesRoute: RouteConfig[] = [
  {
    name: 'CursesHome',
    path: '/:lang/courses',
    component: CoursesHome,
  },
  {
    name: 'CursesCategory',
    path: '/:lang/courses/category-:slug/:id',
    component: CoursesCategory,
  },
  {
    name: 'CursesSingle',
    path: '/:lang/courses/single-:slug/:id',
    component: CoursesSingle,
  },
];
