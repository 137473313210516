import axios from '../axios';
import { AxiosResponse, AxiosError } from 'axios';
import { ITrainingDelivers, ITrainingDeliver, ITrainingFormats, ITrainingFormat } from '@/types/training';
import store from '@/store';

const TRAINING_DELIVERY_URI = `api/v2/pages/?type=academy.TrainingDeliveryIndexPage&fields=*&locale=${store.getters['Languages/getCurrent']}`;
const TRAINING_FORMAT_URI = `api/v2/training-format/?locale=${store.getters['Languages/getCurrent']}`;

const trainingDelivery = async (): Promise<ITrainingDeliver | null> => {
  return await axios
    .get(TRAINING_DELIVERY_URI)
    .then((response: AxiosResponse) => {
      const { items }: ITrainingDelivers = response.data;
      return items[0];
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return null;
    });
};

const trainingFormat = async (): Promise<ITrainingFormat[] | null> => {
  return await axios
    .get(TRAINING_FORMAT_URI)
    .then((response: AxiosResponse) => {
      const { items }: ITrainingFormats = response.data;
      return items;
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return null;
    });
};

export { trainingDelivery, trainingFormat };
