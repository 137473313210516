


















































































































































































import { Component, Vue } from 'vue-property-decorator';
import { ILanguage } from '@/types/language';
import { Action } from 'vuex-class';
import BackTop from '@/components/BackTop.vue';

@Component({
  components: {
    BackTop,
  },
})
export default class Footer extends Vue {
  @Action('Languages/updateLanguage') updateLanguage!: any;

  public ILanguage = ILanguage;

  public switchLanguage(lang: ILanguage) {
    this.updateLanguage({ lang });
    this.$router.replace({ params: { lang } });
    return this.$router.go(0);
  }
}
