


























import { Component, Vue } from 'vue-property-decorator';
import HeaderMenu from '@/components/HeaderMenu.vue';
import Sidenav from '@/components/Sidenav.vue';

@Component({
  components: {
    HeaderMenu,
    Sidenav,
  },
})
export default class Header extends Vue {}
