


































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class PageBreadcrumbs extends Vue {
  @Prop({ required: false, default: 0 }) marginTop?: number;
  @Prop({ required: false, default: 0 }) marginBottom?: number;
}
