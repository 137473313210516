import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
class Thumbnail extends VuexModule {
  private image = '';

  public get getCurrent(): string {
    return this.image;
  }

  @Mutation setImage({ url }: { url: string }): void {
    this.image = url;
  }

  @Action updateImage({ url }: { url: string }): void {
    this.context.commit('setImage', { url });
  }
}
export { Thumbnail };
