import WhoWeAre from '@/views/academy/WhoWeAre.vue';
import Venue from '@/views/academy/Venue.vue';
import TrainingDeliveryOptions from '@/views/academy/TrainingDeliveryOptions.vue';
import AcademyTestimonials from '@/views/academy/Testimonials.vue';

export const AcademyRoute = [
  {
    name: 'AcademyWhoWeAre',
    path: '/:lang/academy-who-we-are',
    component: WhoWeAre,
  },
  {
    name: 'AcademyVenue',
    path: '/:lang/academy-venue',
    component: Venue,
  },
  {
    name: 'AcademyTrainingDeliveryOptions',
    path: '/:lang/academy-training-delivery-options',
    component: TrainingDeliveryOptions,
  },
  {
    name: 'AcademyTestimonials',
    path: '/:lang/academy-testimonials',
    component: AcademyTestimonials,
  },
];
