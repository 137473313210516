

























































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import Button from '@/components/Button.vue';
import BaseBotInput from '@/components/BaseBotInput.vue';
import { IButtonType, IButtonSize, IButtonColor } from '@/types/buttons';
import { IFormEventRegister } from '@/types/forms';
import validatorForm from '@/helpers/validatorForm';
import {formContact, formEventRegister} from '@/service/api/module/form';
import { Checkbox as ACheckbox } from 'ant-design-vue';
import { IEmitRoot } from '@/types/emit';

declare global {
    interface Window {
        dataLayer: any;
    }
}

@Component({
  components: {
    Button,
    BaseBotInput,
    ACheckbox,
  },
})
export default class EventsRegister extends Vue {
  /* eslint-disable @typescript-eslint/camelcase */

  @Prop({ required: true, default: 0 }) courseId!: number;
  @Prop({ required: true, default: 0 }) eventId!: number;
  @Prop({ required: false, default: false }) interested!: boolean;

  public isLoading = false;
  public sendSuccess = false;
  public sendError = false;
  public botField = null;

  public IButtonType = IButtonType;
  public IButtonSize = IButtonSize;
  public IButtonColor = IButtonColor;

  public form: IFormEventRegister = {
    full_name: '',
    organization: '',
    email: '',
    course_id: this.courseId,
    event_id: this.eventId,
    phone_number: '',
    message: '',
    newsletter: false,
    privacy_policy: false,
    interested: this.interested
  };

  public formError: IFormEventRegister = {
    full_name: false || '',
    organization: false || '',
    email: false || '',
    phone_number: false || '',
    message: false || '',
    newsletter: false || '',
    privacy_policy: false || '',
    interested: false || ''
  };

  public onCancel(e: Event) {
    e.preventDefault();

    this.sendSuccess = false;
    this.sendError = false;

    this.form = {
      full_name: '',
      organization: '',
      email: '',
      course_id: this.courseId,
      event_id: this.eventId,
      phone_number: '',
      message: '',
      newsletter: false,
      privacy_policy: false,
      interested: false
    };

    this.formError = {
      full_name: '',
      organization: false,
      email: false,
      phone_number: false,
      message: false,
      newsletter: false,
      privacy_policy: false,
      interested: false
    };

    this.$root.$emit(IEmitRoot.MODAL_CLOSE);
  }

  public async submitForm(e: Event) {
    e.preventDefault();
    if (!this.isFormValid || this.isLoading) {
      return;
    }

    if (this.botField !== null) {
      this.sendError = true;
      return;
    }

    this.isLoading = true;
    try {
      await formEventRegister(this.form)
      this.sendSuccess = true;
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'lead_generation',
        'form_name': 'events_register',
        'user_email': this.form.email
      });
    } catch (e) {
      this.sendError = true;
    } finally {
      this.isLoading = false;
    }
  }

  private get isFormValid(): boolean {
    /* eslint-disable @typescript-eslint/camelcase */

    this.formError.full_name = validatorForm.fullName(this.form.full_name) || '';
    this.formError.message = validatorForm.message(this.form.message) || '';
    this.formError.email = validatorForm.email(this.form.email) || '';
    this.formError.privacy_policy = validatorForm.checkbox(this.form.privacy_policy) || '';

    if (this.formError.message || this.formError.full_name || this.formError.email || this.formError.privacy_policy) {
      return false;
    }

    return true;
  }

  public get router() {
    return this.$router;
  }
}
