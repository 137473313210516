











































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { coursesCategorySingle, coursesByCategoryId } from '@/service/api/module/course';
import { ICourses, ICourseSingle, ICoursesCategoriesSingle } from '@/types/courses';
import PageBreadcrumbs from '@/components/PageBreadcrumbs.vue';
import CoursesCategoryCard from '@/components/CoursesCategoryCard.vue';
import Button from '@/components/Button.vue';
import CoursesCard from '@/components/CoursesCard.vue';
import Filters from '@/components/Filters.vue';
import PageContainer from '@/components/PageContainer.vue';
import PageTitle from '@/components/PageTitle.vue';
import PageDescription from '@/components/PageDescription.vue';
import { permalink } from '@/helpers/permalink';
import { Route } from 'vue-router';
import { MetaInfo } from 'vue-meta';
import { IButtonType, IButtonSize, IButtonColor } from '@/types/buttons';
import Loading from '@/components/Loading.vue';
import { IEventsFilters } from '@/types/events';

@Component({
  metaInfo(this: Vue & { $t: string; category: ICoursesCategoriesSingle }): MetaInfo {
    return {
      title: this.$t('views.courses.category.titleSeo', { categoryName: this.category?.name }).toString(),
    };
  },
  components: {
    PageBreadcrumbs,
    Button,
    CoursesCategoryCard,
    CoursesCard,
    Filters,
    PageContainer,
    PageTitle,
    PageDescription,
    Loading,
  },
})
export default class CursesCategory extends Vue {
  @Watch('$route', { immediate: true, deep: true })
  private async onChanged(route: Route) {
    const categoryId = Number(route.params.id);
    if (!categoryId) {
      return this.$router.push({ name: 'Home' });
    }
    this.getCategory({ id: categoryId });
  }

  public IButtonType = IButtonType;
  public IButtonSize = IButtonSize;
  public IButtonColor = IButtonColor;
  private category: ICoursesCategoriesSingle | null = null;
  private categoryId?: number;
  private categorySlug?: string;
  private backgroundImage?: string;
  private courses: ICourseSingle[] | null = [];
  private coursesCount?: number = 0;
  public coursesLimit = 20;
  public coursesOffset = 20;
  public isLoadingNext = false;
  public isNextButtons = false;
  private permalink = permalink;
  public isLoading = true;
  public filters?: IEventsFilters | null = null;

  private async getCategory({ id }: { id: number }) {
    this.isLoading = true;
    this.categoryId = +id;
    this.category = await coursesCategorySingle({ id: this.categoryId, locale: this.$route.params.lang });

    if (!this.category) {
      return this.$router.push({ name: 'Home' });
    }

    this.categorySlug = permalink({ text: this.category.name });
    this.backgroundImage = this.category?.hero_image ? this.category?.hero_image : '';
    const courses = await coursesByCategoryId({ categoryId: this.categoryId });

    this.isLoading = false;

    if (courses) {
      this.filters = courses.filters;
      this.courses = courses.items;
      this.coursesCount = courses.meta.total_count;
      this.isNextButtons = this.courses?.length === this.coursesLimit ? true : false;
    }
  }

  private submitObjectFilters(courses: ICourseSingle[] | null) {
    this.courses = courses;
    this.isNextButtons = courses ? (courses.length === this.coursesOffset ? true : false) : false;
  }

  public async loadMore() {
    if (this.isLoadingNext || !this.courses || !this.categoryId || !this.isNextButtons) {
      return;
    }

    this.isLoadingNext = true;
    const courses: ICourses | null = await coursesByCategoryId({ categoryId: this.categoryId, offset: this.coursesLimit });
    if (courses) {
      this.courses.push(...courses.items);
      this.isNextButtons = courses.meta.total_count === this.coursesOffset ? true : false;
      this.coursesLimit = this.coursesLimit + this.coursesOffset;
    }
    this.isLoadingNext = false;
  }
}
