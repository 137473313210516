







































import { Component, Vue, Prop } from 'vue-property-decorator';
import Button from '@/components/Button.vue';
3
@Component({components: {Button}})
export default class HomepagePartnership extends Vue {
  @Prop({ required: false, default: '' }) title!: string;
}
