







































































import { Component, Watch, Vue } from 'vue-property-decorator';
import PageBreadcrumbs from '@/components/PageBreadcrumbs.vue';
import PageContainer from '@/components/PageContainer.vue';
import PageTitle from '@/components/PageTitle.vue';
import { MetaInfo } from 'vue-meta';
import PageDescription from '@/components/PageDescription.vue';
import NewsPostCard from '@/components/NewsPostCard.vue';
import { INewsPost } from '@/types/news';
import { newsPost } from '@/service/api/module/news';
import { Action } from 'vuex-class';
import { Route } from 'vue-router';

@Component({
  metaInfo(this: Vue & { $t: string; news: INewsPost }): MetaInfo {
    return {
      title: this.news?.title,
    };
  },
  components: {
    PageContainer,
    PageBreadcrumbs,
    PageTitle,
    PageDescription,
    NewsPostCard,
  },
})
export default class NewsPost extends Vue {
  @Action('BodyBackground/updateGrey') updateGrey!: any;

  @Watch('$route', { immediate: true, deep: true })
  private async onChanged(route: Route) {
    const newsId = Number(route.params.id);
    if (!newsId) {
      return this.$router.push({ name: 'Home' });
    }
    this.getNews({ id: newsId });
  }

  private newsId!: number;
  public news: INewsPost | null = null;

  public async created() {
    this.updateGrey({ status: true });
  }

  private destroyed(): void {
    this.updateGrey({ status: false });
  }

  private async getNews({ id }: { id: number }) {
    this.newsId = id;
    this.news = await newsPost({ id: this.newsId });
    if (!this.news) {
      return this.$router.push({ name: 'Home' });
    }
  }

  public YouTubeId(url: string) {
    const uri = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    const id = uri[2] !== undefined ? uri[2].split(/[^0-9a-z_-]/i)[0] : uri[0];
    if (!id) {
      return null;
    }
    return id;
  }
}
