export enum IButtonType {
  PRIMARY,
  SECONDARY,
  TERTIARY,
}

export enum IButtonSize {
  MEDIUM,
  LARGE,
}

export enum IButtonColor {
  DEFAULT,
  TRANSPARENT,
  WHITE,
}
