





































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import PageBreadcrumbs from '@/components/PageBreadcrumbs.vue';
import PageContainer from '@/components/PageContainer.vue';
import PageTitle from '@/components/PageTitle.vue';
import BaseBotInput from '@/components/BaseBotInput.vue';
import { MetaInfo } from 'vue-meta';
import PageDescription from '@/components/PageDescription.vue';
import Button from '@/components/Button.vue';
import { IButtonType, IButtonSize, IButtonColor } from '@/types/buttons';
import { Checkbox as ACheckbox } from 'ant-design-vue';
import { IFormContact } from '@/types/forms';
import validatorForm from '@/helpers/validatorForm';
import { formContact } from '@/service/api/module/form';
import { IContact } from '@/types/contact';
import { contact as contactFetch } from '@/service/api/module/contact';
import { Getter } from 'vuex-class';
import { ILanguage } from '@/types/language';
import Loading from '@/components/Loading.vue';

@Component({
  metaInfo(this: Vue & { $t: string }): MetaInfo {
    return {
      title: this.$t('views.contact.titleSeo').toString(),
    };
  },
  components: {
    PageContainer,
    PageBreadcrumbs,
    PageTitle,
    PageDescription,
    Button,
    ACheckbox,
    BaseBotInput,
    Loading,
  },
})
export default class ContactHome extends Vue {
  /* eslint-disable @typescript-eslint/camelcase */

  @Getter('Languages/getCurrent') language!: ILanguage;

  public isLoading = false;
  public sendSuccess = false;
  public sendError = false;
  public botField = null;
  public contact: IContact | null = null;
  public isLoadingDefault = true;

  public IButtonType = IButtonType;
  public IButtonSize = IButtonSize;
  public IButtonColor = IButtonColor;

  public form: IFormContact = {
    full_name: '',
    organization: '',
    email: '',
    message: '',
    newsletter: false,
    privacy_policy: false,
  };

  public formError: IFormContact = {
    full_name: false || '',
    organization: false || '',
    email: false || '',
    message: false || '',
    newsletter: false || '',
    privacy_policy: false || '',
  };

  private async created() {
    this.contact = await contactFetch();
    this.isLoadingDefault = false;
  }

  public async submitForm(e: Event) {
    e.preventDefault();
    if (!this.isFormValid || this.isLoading) {
      return;
    }

    if (this.botField !== null) {
      this.sendError = true;
      return;
    }

    this.isLoading = true;
    try {
      await formContact(this.form);
      this.sendSuccess = true;
    } catch (e) {
      this.sendError = true;
    } finally {
      this.isLoading = false;
    }
  }

  private get isFormValid(): boolean {
    /* eslint-disable @typescript-eslint/camelcase */

    this.formError.full_name = validatorForm.fullName(this.form.full_name) || '';
    this.formError.email = validatorForm.email(this.form.email) || '';
    this.formError.privacy_policy = validatorForm.checkbox(this.form.privacy_policy) || '';
    this.formError.message = validatorForm.message(this.form.message) || '';

    if (this.formError.message || this.formError.full_name || this.formError.email || this.formError.privacy_policy) {
      return false;
    }

    return true;
  }

  public get router() {
    return this.$router;
  }
}
