import { render, staticRenderFns } from "./PageDescription.vue?vue&type=template&id=f83b9190&scoped=true&"
import script from "./PageDescription.vue?vue&type=script&lang=ts&"
export * from "./PageDescription.vue?vue&type=script&lang=ts&"
import style0 from "./PageDescription.vue?vue&type=style&index=0&id=f83b9190&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f83b9190",
  null
  
)

export default component.exports