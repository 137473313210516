import NewsHomepage from '@/views/news/Homepage.vue';
import NewsPost from '@/views/news/Single.vue';

export const NewsRoute = [
  {
    name: 'NewsHomepage',
    path: '/:lang/news',
    component: NewsHomepage,
  },
  {
    name: 'NewsPost',
    path: '/:lang/news/single-:slug/:id',
    component: NewsPost,
  },
];
