

































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SearchBar extends Vue {
  @Prop({ required: false, default: false }) isSearch?: boolean;
  @Prop({ required: false, default: '' }) searchText!: string;

  // public searchText: string | null = null;

  public handleSearch(searchText?: string) {
    this.$emit('searched', searchText);
  }
}
