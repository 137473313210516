








































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ICourseSingle } from '@/types/courses';
import { courseDuration } from '@/helpers/courseDuration';
import { eventDatetime } from '@/helpers/eventDatetime';

@Component({})
export default class CoursesCard extends Vue {
  @Prop({ required: false, default: false }) course!: ICourseSingle;

  public courseDuration = courseDuration;
  public eventDatetime = eventDatetime;
}
