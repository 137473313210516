



































import { Component, Vue } from 'vue-property-decorator';
import PageBreadcrumbs from '@/components/PageBreadcrumbs.vue';
import PageContainer from '@/components/PageContainer.vue';
import PageTitle from '@/components/PageTitle.vue';
import { MetaInfo } from 'vue-meta';
import PageDescription from '@/components/PageDescription.vue';
import { cookies as cookiesFetch } from '@/service/api/module/cookies';
import { ICookie } from '@/types/cookies';

@Component({
  metaInfo(this: Vue & { $t: string }): MetaInfo {
    return {
      title: this.$t('views.cookies.titleSeo').toString(),
    };
  },
  components: {
    PageContainer,
    PageBreadcrumbs,
    PageTitle,
    PageDescription,
  },
})
export default class Cookies extends Vue {
  public cookies: ICookie | null = null;

  private async created() {
    this.cookies = await cookiesFetch();
  }
}
