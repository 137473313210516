


















































import { Component, Vue } from 'vue-property-decorator';
import PageBreadcrumbs from '@/components/PageBreadcrumbs.vue';
import PageContainer from '@/components/PageContainer.vue';
import PageTitle from '@/components/PageTitle.vue';
import Button from '@/components/Button.vue';
import { IButtonType, IButtonSize, IButtonColor } from '@/types/buttons';
import { MetaInfo } from 'vue-meta';
import PageDescription from '@/components/PageDescription.vue';
import NewsPostCard from '@/components/NewsPostCard.vue';
import {INews, INewsPost} from '@/types/news';
import { newsPosts } from '@/service/api/module/news';
import { Action } from 'vuex-class';

@Component({
  metaInfo(this: Vue & { $t: string }): MetaInfo {
    return {
      title: this.$t('views.news.homepage.titleSeo').toString(),
    };
  },
  components: {
    PageContainer,
    PageBreadcrumbs,
    PageTitle,
    PageDescription,
    NewsPostCard,
    Button
  },
})
export default class NewsHomepage extends Vue {
  @Action('BodyBackground/updateGrey') updateGrey!: any;

  public news: INewsPost[] | null = null;
  public newsLimit = 20;
  public newsOffset = 20;
  public newsCount: string | number = '';
  public isLoadingNext = false;
  public IButtonType = IButtonType;
  public IButtonSize = IButtonSize;
  public IButtonColor = IButtonColor;

  public async created() {
    this.updateGrey({ status: true });
    const news = await newsPosts();
    if (news) {
      this.news = news.items;
      this.newsCount = news.meta.total_count;
    }
  }

  get isNextButtons() {
    return this.news && this.news?.length < this.newsCount;
  }

  private destroyed(): void {
    this.updateGrey({ status: false });
  }

  public async loadMore() {
    if (this.isLoadingNext || !this.news || !this.isNextButtons) {
      return;
    }

    this.isLoadingNext = true;
    const news: INews | null = await newsPosts(this.newsLimit);
    if (news) {
      this.news.push(...news.items);
      this.newsLimit = this.newsLimit + this.newsOffset;
    }
    this.isLoadingNext = false;
  }
}
