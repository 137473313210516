









import { Component, Vue } from 'vue-property-decorator';
import { Spin as ASpin } from 'ant-design-vue';

@Component({
  components: {
    ASpin,
  },
})
export default class Loading extends Vue {}
