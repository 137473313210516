/* eslint-disable @typescript-eslint/camelcase */

import axios from '../axios';
import { AxiosResponse, AxiosError } from 'axios';
import { IClient, IClients } from '@/types/clients';
import store from '@/store';

const CLIENTS_URI = `api/v2/pages/?type=client.OurClientIndexPage&fields=*&limit=100&locale=${store.getters['Languages/getCurrent']}`;

const clients = async (): Promise<IClient | null> => {
  return await axios
    .get(CLIENTS_URI)
    .then((response: AxiosResponse) => {
      const { items }: IClients = response.data;
      return items[0];
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return null;
    });
};

export { clients };
