
















































































































import { Component, Vue } from 'vue-property-decorator';
import { coursesCategory, coursesLists } from '@/service/api/module/course';
import { ICourses, ICourseSingle, ICoursesCategoriesSingle } from '@/types/courses';
import { IEventsFilters } from '@/types/events';
import { IButtonType, IButtonSize, IButtonColor } from '@/types/buttons';
import PageBreadcrumbs from '@/components/PageBreadcrumbs.vue';
import CoursesCategoryCard from '@/components/CoursesCategoryCard.vue';
import Button from '@/components/Button.vue';
import CoursesCard from '@/components/CoursesCard.vue';
import Filters from '@/components/Filters.vue';
import PageContainer from '@/components/PageContainer.vue';
import PageTitle from '@/components/PageTitle.vue';
import { permalink } from '@/helpers/permalink';
import { MetaInfo } from 'vue-meta';
import { coursesCategoryBackground as coursesCategoryBackgroundFetch } from '@/service/api/module/course';
import { ICoursesCategoriesBackground } from '@/types/courses';
import Loading from '@/components/Loading.vue';

@Component({
  metaInfo(): MetaInfo {
    return {
      title: this.$t('views.courses.home.titleSeo').toString(),
    };
  },
  components: {
    PageBreadcrumbs,
    Button,
    CoursesCategoryCard,
    CoursesCard,
    Filters,
    PageContainer,
    PageTitle,
    Loading,
  },
})
export default class CursesHome extends Vue {
  public categories: ICoursesCategoriesSingle[] | null = [];
  public courses: ICourseSingle[] | null = [];
  public coursesLimit = 20;
  public coursesOffset = 20;
  public coursesCount: string | number = '';
  public coursesCategoryBackground: ICoursesCategoriesBackground | null = null;
  public isLoadingNext = false;
  public IButtonType = IButtonType;
  public IButtonSize = IButtonSize;
  public IButtonColor = IButtonColor;
  public permalink = permalink;
  public isLoading = true;
  public filters?: IEventsFilters | null = null;

  get isNextButtons() {
    return this.courses && this.courses?.length < this.coursesCount;
  }

  private async created() {
    const [categories, courses, coursesCategoryBackground] = await Promise.all([
      coursesCategory(this.$route.params.lang),
      coursesLists(),
      coursesCategoryBackgroundFetch(),
    ]);

    this.isLoading = false;

    this.categories = categories;
    if (courses) {
      this.courses = courses.items;
      this.coursesCount = courses.meta.total_count;
      this.filters = courses.filters;
    }
    this.coursesCategoryBackground = coursesCategoryBackground;
  }

  private findCoursesCategory({ categoryId }: { categoryId: number }): { id: number; slug: string } | null {
    const category = this.categories?.find((category: ICoursesCategoriesSingle) => category.id === categoryId);
    if (!category) {
      return null;
    }
    const { id, name } = category;
    return {
      id: id,
      slug: permalink({ text: name }),
    };
  }

  private submitObjectFilters(courses: ICourseSingle[] | null) {
    this.courses = courses;
  }

  public async loadMore() {
    if (this.isLoadingNext || !this.courses || !this.isNextButtons) {
      return;
    }

    this.isLoadingNext = true;
    const courses: ICourses | null = await coursesLists(this.coursesLimit);
    if (courses) {
      this.courses.push(...courses.items);
      this.coursesLimit = this.coursesLimit + this.coursesOffset;
    }
    this.isLoadingNext = false;
  }
}
