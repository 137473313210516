























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class BaseInput extends Vue {
  @Prop({ required: false, default: false }) hidden?: boolean;
  @Prop({ required: false, default: '' }) label?: string;
  @Prop({ required: true }) name!: string;
}
