














import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class PageContainer extends Vue {
  @Prop({ required: false, default: null }) backgroundImage?: string;
  @Prop({ required: false, default: null }) backgroundHeight?: number;
  @Prop({ required: false, default: false }) backgroundGrey?: boolean;
  @Prop({ required: false, default: 100 }) paddingTop?: number;
  @Prop({ required: false, default: 65 }) paddingBottom?: number;
  @Prop({ required: false, default: false }) hideImage?: boolean;
}
