/* eslint-disable @typescript-eslint/camelcase */

import axios from '../axios';
import { AxiosResponse, AxiosError } from 'axios';
import { IAbelineExpirience, IExpirience } from '@/types/expirience';
const URI = `api/v2/pages/`;

const abelineExpirience = async (lang: string): Promise<IExpirience | null> => {
  return await axios
    .get(URI, {params: {
      type: 'academy.WhyAbelineIndexPage',
      fields: '*',
      locale: lang
    }})
    .then((response: AxiosResponse) => {
      const { items }: IAbelineExpirience = response.data;
      return items[0];
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return null;
    });
};

export { abelineExpirience };
