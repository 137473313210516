import axios from '../axios';
import { AxiosResponse, AxiosError } from 'axios';
import { INews, INewsPost } from '@/types/news';
import store from '@/store';

const NEWS_POST_URI = `api/v2/pages/?type=blog.BlogPostPage&fields=*`;

const newsPosts = async (offset?: number): Promise<INews | null> => {
  return await axios
    .get(`${NEWS_POST_URI}&locale=${store.getters['Languages/getCurrent']}&order=-date&offset=${offset ?? 0}`)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return null;
    });
};

const newsPost = async ({ id }: { id: number }): Promise<INewsPost | null> => {
  return await axios
    .get(`${NEWS_POST_URI}&id=${id}&locale=${store.getters['Languages/getCurrent']}`)
    .then((response: AxiosResponse) => {
      const { items }: INews = response.data;
      return items[0];
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return null;
    });
};

export { newsPosts, newsPost };
