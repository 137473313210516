/* eslint-disable @typescript-eslint/camelcase */

import axios from '../axios';
import { AxiosResponse, AxiosError } from 'axios';
import {
  ICourses,
  ICourseSingle,
  ICoursesCategories,
  ICoursesCategoriesSingle,
  ICoursesCategoriesBackgrounds,
  ICoursesCategoriesBackground,
} from '@/types/courses';
import { IFiltersTypesQuery } from '@/types/filters';
import { removeEmptyParams } from '@/helpers/removeEmptyParams';
import store from '@/store';

const COURSE_PAGE_URI = `api/v2/pages/?type=course.CoursePage&fields=*&locale=${store.getters['Languages/getCurrent']}&filters=true`;
const COURSE_CATEGORY_URI = `api/v2/category/`;
const COURSE_BACKGROUND_URI = `api/v2/pages/?type=course.CourseIndexPage&fields=*&locale=${store.getters['Languages/getCurrent']}`;
const COURSE_TESTIMONIAL_URI = `api/v2/testimonial/?limit=50&locale=${store.getters['Languages/getCurrent']}`;

const coursesCategory = async (locale: string): Promise<ICoursesCategoriesSingle[] | null> => {
  return await axios
    .get(COURSE_CATEGORY_URI, {params: {
      locale
    }})
    .then((response: AxiosResponse) => {
      const { items }: ICoursesCategories = response.data;
      return items;
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return null;
    });
};

const coursesCategorySingle = async ({ id, locale }: { id: number; locale: string }): Promise<ICoursesCategoriesSingle | null> => {
  return await axios
    .get(`${COURSE_CATEGORY_URI}`, {params: {locale, id}})
    .then((response: AxiosResponse) => {
      const { items }: ICoursesCategories = response.data;
      return items[0];
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return null;
    });
};

const coursesLists = async (offset?: number): Promise<ICourses | any | null> => {
  const uri = offset ? `${COURSE_PAGE_URI}&offset=${offset}` : COURSE_PAGE_URI;
  return await axios
    .get(uri)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return null;
    });
};

const coursesByCategoryId = async ({ categoryId, offset }: { categoryId: number; offset?: number }): Promise<ICourses | any | null> => {
  const uri = `${COURSE_PAGE_URI}&course_category=${categoryId}&locale=${store.getters['Languages/getCurrent']}`;
  return await axios
    .get(`${uri}${offset ? '&offset=' + offset : ''}`)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return null;
    });
};

const coursesByFilters = async ({ params, searchText }: { params: IFiltersTypesQuery; searchText?: string }): Promise<any> => {
  const query = `&course_category=${params.category}&course_id=${params.course}&course_language=${params.language}&course_location=${params.location}&course_month=${params.datetime}&course_training_format=${params.training_formats}`;

  return await axios
    .get(`${COURSE_PAGE_URI}${removeEmptyParams(query)}${searchText ? '&course_title=' + searchText : ''}`)
    .then((response: AxiosResponse) => {
      const { items, filters }: any = response.data;
      return {
        courses: items,
        filters,
      };
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return null;
    });
};

const coursesSingle = async ({ id }: { id: number }): Promise<ICourseSingle | any | null> => {
  return await axios
    .get(`${COURSE_PAGE_URI}&id=${id}`)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return null;
    });
};

const courseTestimonial = async ({ id }: { id: number }): Promise<ICourseSingle | any | null> => {
  return await axios
    .get(`${COURSE_TESTIMONIAL_URI}&course_id=${id}`)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return null;
    });
};

const coursesCategoryBackground = async (): Promise<ICoursesCategoriesBackground | null> => {
  return await axios
    .get(COURSE_BACKGROUND_URI)
    .then((response: AxiosResponse) => {
      const { items }: ICoursesCategoriesBackgrounds = response.data;
      return items && items[0] ? items[0] : null;
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return null;
    });
};

export {
  coursesCategory,
  coursesCategorySingle,
  coursesByCategoryId,
  coursesLists,
  coursesByFilters,
  coursesSingle,
  courseTestimonial,
  coursesCategoryBackground,
};
