







































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router';

@Component({})
export default class PageTitle extends Vue {
  @Prop({ required: false, default: null }) title!: string;
  @Prop({ required: false, default: null }) titleSpan?: string;
  @Prop({ required: false, default: 25 }) marginTop?: number;
  @Prop({ required: false, default: false }) isSecond?: boolean;
  @Prop({ required: false, default: false }) isEvent?: boolean;
  @Prop({ required: false, default: false }) isRouterLink?: boolean;
  @Prop({ required: false, default: false }) centerText?: boolean;
  @Prop({ required: false, default: false }) routerLink?: Route;
}
