/* eslint-disable @typescript-eslint/camelcase */

import axios from '../axios';
import { AxiosResponse, AxiosError } from 'axios';
import { IContact, IContacts } from '@/types/contact';
import store from '@/store';

const CONTACT_URI = `api/v2/pages/?type=contact.ContactIndexPage&fields=*&locale=${store.getters['Languages/getCurrent']}`;

const contact = async (): Promise<IContact | null> => {
  return await axios
    .get(CONTACT_URI)
    .then((response: AxiosResponse) => {
      const { items }: IContacts = response.data;
      return items[0];
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return null;
    });
};

export { contact };
