import { IEventLocation, IEventStatus } from './events';

export type ICourses = {
  meta: {
    total_count: number;
  };
  items: ICourseSingle[];
};

export type ICoursesCategories = {
  meta: {
    total_count: number;
  };
  items: ICoursesCategoriesSingle[];
};

// @todo naming
export type ICoursesCategoriesSingle = {
  id: number;
  name: string;
  description: string;
  short_description: string;
  hero_image: string;
};

export enum ICoursesConcludedWith {
  EXAM = 'exam',
  EXAM_AND_CERTIFICATE = 'exam & certificate',
}

export type ICourseSingle = {
  id: number;
  meta: {
    type: string;
    detail_url: string;
    html_url: string;
    slug: string;
    show_in_menus: boolean;
    seo_title: string;
    search_description: string;
    first_published_at: Date;
    locale: string;
  };
  title: string;
  overview: string;
  program: string;
  exam_and_certificate: string;
  educational_approach: string;
  feedback: string;
  formatted_feedback: {
    company: string;
    feedback: string;
    name: string;
  }[];
  duration: string;
  concluded_with: ICoursesConcludedWith;
  hero_image: string;
  success_rate: string | number;
  categories: {
    id: number;
    meta: {
      type: string;
    };
  }[];
  tags: string[];
  closest_event: {
    id: number;
    title: string;
    location: IEventLocation;
    status: IEventStatus;
    languages: number[];
    start_date: Date;
    end_date: Date;
  };
  best_seller: boolean;
  brochure: string | null;
};

export type ICoursesCategoriesBackgrounds = {
  meta: {
    total_count: number;
  };
  items: ICoursesCategoriesBackground[];
};

export type ICoursesCategoriesBackground = {
  id: number;
  meta: {
    type: string;
    detail_url: string;
    html_url: string;
    slug: string;
    show_in_menus: boolean;
    seo_title: string;
    search_description: string;
    first_published_at: string;
    locale: string;
  };
  title: string;
  hero_image: string;
};

export enum ICourseConcludedWith {
  CERTIFICATION = 'certification',
  EXAM_CERTIFICATION = 'exam & certification',
  NO_EXAM_NO_CERTIFICATION = 'no exam & no certification',
}
