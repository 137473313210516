import axios from '../axios';
import { AxiosResponse, AxiosError } from 'axios';
import { IShedule, IShedules } from '@/types/shedule';
import store from '@/store';

const SHEDULE_URI = `api/v2/pages/?type=event.EventIndexPage&fields=*`;

const shedule = async (): Promise<IShedule | null> => {
  return await axios
    .get(`${SHEDULE_URI}&locale=${store.getters['Languages/getCurrent']}`)
    .then((response: AxiosResponse) => {
      const { items }: IShedules = response.data;
      return items[0];
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return null;
    });
};

export { shedule };
