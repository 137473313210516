





























































































import { Component, Vue } from 'vue-property-decorator';
import PageContainer from '@/components/PageContainer.vue';
import PageTitle from '@/components/PageTitle.vue';
import PageBreadcrumbs from '@/components/PageBreadcrumbs.vue';
import Filters from '@/components/Filters.vue';
import { MetaInfo } from 'vue-meta';
import { events as eventsFetch } from '@/service/api/module/events';
import { IEventsSingle } from '@/types/events';
import EventsCard from '@/components/EventsCard.vue';
import sortBy from 'lodash.sortby';
import getTime from 'date-fns/getTime';
import { IButtonType, IButtonSize, IButtonColor } from '@/types/buttons';
import Button from '@/components/Button.vue';
import { IShedule } from '@/types/shedule';
import { shedule as sheduleFetch } from '@/service/api/module/shedule';
import Loading from '@/components/Loading.vue';
import { IEventsFilters } from '@/types/events';

@Component({
  metaInfo(this: Vue & { $t: string }): MetaInfo {
    return {
      title: this.$t('components.header.menu.schedule').toString(),
    };
  },
  components: {
    PageContainer,
    PageTitle,
    PageBreadcrumbs,
    Filters,
    EventsCard,
    Button,
    Loading,
  },
})
export default class ScheduleHome extends Vue {
  public shedule: IShedule | null = null;
  public events?: IEventsSingle[] | null = null;
  public eventsOffset = 20;
  public eventsLimit = 20;
  public sorting: { name: string; active: boolean }[] = [
    { name: this.$t('views.schedule.home.sorting.name').toString(), active: false },
    { name: this.$t('views.schedule.home.sorting.date').toString(), active: false },
    { name: this.$t('views.schedule.home.sorting.location').toString(), active: false },
    { name: this.$t('views.schedule.home.sorting.language').toString(), active: false },
  ];

  public isLoadingNext = false;
  public isNextButtons = false;
  public IButtonType = IButtonType;
  public IButtonSize = IButtonSize;
  public IButtonColor = IButtonColor;
  public isLoading = true;
  public filters?: IEventsFilters | null = null;

  private async created() {
    const [shedule, events] = await Promise.all([sheduleFetch(), eventsFetch()]);

    this.shedule = shedule;
    this.events = events.items;
    this.filters = events.filters;
    this.isNextButtons = this.events?.length === this.eventsLimit ? true : false;
    this.isLoading = false;
  }

  private submitObjectFilters(events: IEventsSingle[] | null) {
    this.events = events;
    this.isNextButtons = events ? (events.length === this.eventsOffset ? true : false) : false;
  }

  private sortingActive({ item, index }: { item: { name: string; active: boolean }; index: number }) {
    this.sorting[index].active = !item.active;
    this.events = sortBy(this.events, (item: IEventsSingle) => {
      const sorting = [];

      if (this.sorting[0].active) {
        sorting.push(item.title);
      }

      if (this.sorting[1].active) {
        sorting.push(getTime(new Date(item.start_date)));
      }

      if (this.sorting[2].active) {
        sorting.push(`${item.location.country} ${item.location.city} ${item.location.name}`);
      }
      if (this.sorting[3].active) {
        sorting.push(item.languages[0].name);
      }

      return sorting && sorting.length ? sorting : [item.id];
    });
  }

  public get backgroundImage(): string | null {
    if (this.shedule && this.shedule.hero_image) {
      return this.shedule.hero_image;
    }
    return null;
  }

  public get title(): string[] | null {
    if (this.shedule && this.shedule.title) {
      return this.shedule.title.split('.');
    }
    return null;
  }

  public async loadMore() {
    if (this.isLoadingNext || !this.events || !this.isNextButtons) {
      return;
    }

    this.isLoadingNext = true;
    const events: IEventsSingle[] | any | null = await eventsFetch(this.eventsLimit);
    if (events) {
      this.events.push(...events.items);
      this.isNextButtons = events.items.length === this.eventsOffset ? true : false;
      this.eventsLimit = this.eventsLimit + this.eventsOffset;
    }
    this.isLoadingNext = false;
  }
}
