import { format, addMonths } from 'date-fns';
import { capitalize } from './capitalize';
import fr from 'date-fns/locale/fr';
import enUS from 'date-fns/locale/en-US';

import i18n from '@/locales';

const switchLocale = () => {
  const locale = i18n.locale;
  if (locale === 'en') {
    return enUS;
  } else if (locale === 'fr') {
    return fr;
  }
};

export const monthCapitalize = ({ date }: { date: Date }) => {
  return format(new Date(date), 'MMMM', { locale: switchLocale() });
};

export const monthRange = ({ startDate, months }: { startDate: Date; months: number }): { name: string; datetime: string }[] => {
  const result = [];
  for (let step = 0; step < months; step++) {
    const month = addMonths(new Date(startDate), step);
    result.push({
      name: capitalize({ text: format(month, 'MMMM', { locale: switchLocale() }) }),
      datetime: format(month, 'yyyy-MM', { locale: switchLocale() }),
    });
  }
  return result;
};
