












































































import { Component, Vue } from 'vue-property-decorator';
import PageBreadcrumbs from '@/components/PageBreadcrumbs.vue';
import PageContainer from '@/components/PageContainer.vue';
import PageTitle from '@/components/PageTitle.vue';
import { MetaInfo } from 'vue-meta';
import PageDescription from '@/components/PageDescription.vue';
import { homepageClients } from '@/service/api/module/homepage';
import { IHomepageClient } from '@/types/homepage';
import { clients as clientsFetch } from '@/service/api/module/clients';
import { IClient } from '@/types/clients';
import Loading from '@/components/Loading.vue';

@Component({
  metaInfo(this: Vue & { $t: string }): MetaInfo {
    return {
      title: this.$t('views.ourClients.titleSeo').toString(),
    };
  },
  components: {
    PageContainer,
    PageBreadcrumbs,
    PageTitle,
    PageDescription,
    Loading,
  },
})
export default class ClientsHome extends Vue {
  public clients: IClient | null = null;
  public clientsImage: IHomepageClient[] | null = null;
  public isLoading = true;

  private async created() {
    const [clients, clientsImage] = await Promise.all([clientsFetch(), homepageClients()]);
    this.clients = clients;
    this.clientsImage = clientsImage;
    this.isLoading = false;
  }
}
