















































































































































































































import { Component, Vue } from 'vue-property-decorator';
import PageBreadcrumbs from '@/components/PageBreadcrumbs.vue';
import PageContainer from '@/components/PageContainer.vue';
import PageTitle from '@/components/PageTitle.vue';
import PageDescription from '@/components/PageDescription.vue';
import CardsWithIcons from '@/components/CardsWithIcons.vue';
import { trainers as trainersFetch, whoWeAre as whoWeAreFetch } from '@/service/api/module/academy';
import { ITrainer, ITrainerLanguage, ITrainerCertificates, IWhoWeAre } from '@/types/academy';
import Loading from '@/components/Loading.vue';
import Masonry from 'masonry-layout';

@Component({
  metaInfo(this: Vue & { $t: string; whoWeAre: IWhoWeAre }): any {
    return {
      title: this.whoWeAre && this.whoWeAre.title ? this.whoWeAre.title : '',
    };
  },
  components: {
    PageContainer,
    PageBreadcrumbs,
    PageTitle,
    PageDescription,
    CardsWithIcons,
    Loading,
  },
})
export default class WhoWeAre extends Vue {
  public whoWeAre?: IWhoWeAre | null = null;
  public trainers?: ITrainer[] | null = null;
  public isLoading = true;

  private async created() {
    const [trainers, whoWeAre] = await Promise.all([trainersFetch(), whoWeAreFetch()]);

    this.isLoading = false;
    if (trainers && trainers.length) {
      trainers.map((trainer: ITrainer) => {
        trainer.courses = trainer.courses.map((item: any) => {
          if (trainer.certificates && trainer.certificates.length) {
            item.certificatesMapping = trainer.certificates.filter(
              (certificate: ITrainerCertificates) => certificate.course_id === item.id,
            );
          }
          return item;
        });
      });
    }

    this.trainers = trainers;
    this.whoWeAre = whoWeAre;

    setTimeout(() => this.masonary(), 1000);
  }

  private masonary() {
    const grid = this.$refs.masonryColumns as any;
    new Masonry(grid, {
      itemSelector: '.grid-item',
      gutter: 30,
    });
  }

  public get cardIcons() {
    return [
      {
        icon: require('@/assets/images/icons/shield-silver.svg'),
        name: this.$t('views.academy.whoWeAre.cardsIcons.practical.title').toString(),
        content: this.$t('views.academy.whoWeAre.cardsIcons.practical.content').toString(),
      },
      {
        icon: require('@/assets/images/icons/diff-silver.svg'),
        name: this.$t('views.academy.whoWeAre.cardsIcons.adapted.title').toString(),
        content: this.$t('views.academy.whoWeAre.cardsIcons.adapted.content').toString(),
      },
      {
        icon: require('@/assets/images/icons/sketch-silver.svg'),
        name: this.$t('views.academy.whoWeAre.cardsIcons.satisfaction.title').toString(),
        content: this.$t('views.academy.whoWeAre.cardsIcons.satisfaction.content').toString(),
      },
    ];
  }

  public get cardCounter() {
    const counter = [];
    this.whoWeAre?.box_1_description ? counter.push(this.whoWeAre?.box_1_description) : '';
    this.whoWeAre?.box_2_description ? counter.push(this.whoWeAre?.box_2_description) : '';
    this.whoWeAre?.box_3_description ? counter.push(this.whoWeAre?.box_3_description) : '';
    this.whoWeAre?.box_4_description ? counter.push(this.whoWeAre?.box_4_description) : '';
    return counter && counter.length ? counter : null;
  }

  public get backgroundImage(): string {
    if (!this.whoWeAre?.hero_image) {
      return require('@/assets/images/background/courseCategory.jpg');
    }
    return this.whoWeAre.hero_image;
  }

  public language({ languages }: { languages: ITrainerLanguage[] }) {
    return languages.map((lang: ITrainerLanguage) => lang.name).join(' / ');
  }

  public trainerEmptyCertificates(certificates: any[]) {
    if (certificates && certificates.length) {
      return certificates.filter((cert: any) => cert.course_id === null);
    }
    return null;
  }
}
