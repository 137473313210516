








































































































import { Carousel3d, Slide } from 'vue-carousel-3d';

import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    Carousel3d,
    Slide
  },
})
export default class PageCarousel extends Vue {
  @Prop({ required: false, default: () => [] }) slides?: Array<[]>;

  private defaultHeight = 272;
  private currentSlideIdx = 0;

  mounted() {
    if (this.enoughSlides) {
      this.$nextTick(() => {
        const sliderItems: any = this.$refs["slide-item"]
        const items = sliderItems.map(el => el.scrollHeight)
        const maxHeight = Math.max(...items)
        const offset = 50;
        this.defaultHeight = maxHeight + offset;
      })
    }
  }

  private get sliderInstance(): any {
    return this.$refs.slider;
  }

  private get nextArrow() {
    return `
      <div class="slider-arrow-btn slider-arrow-btn--next">
      </div>
    `
  }
  private get prevArrow() {
    return `
      <div class="slider-arrow-btn slider-arrow-btn--prev">
      </div>
    `
  }

  private get enoughSlides() {
    return this.slides && this.slides.length > 1;
  }

  private setSlideIdx(index: number) {
    this.currentSlideIdx = index;
  }

  private setActiveSlide(index: number) {
    this.sliderInstance.goSlide(index);
    this.currentSlideIdx = this.sliderInstance.currentIndex;
  }

}
