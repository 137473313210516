




























































































import { Component, Emit, Prop, Watch, Vue } from 'vue-property-decorator';
import { IButtonType, IButtonSize, IButtonColor } from '@/types/buttons';
import { IEventsFiltersSingle } from '@/types/events';
import { IFiltersTypes, IFilterState } from '@/types/filters';
import Button from '@/components/Button.vue';
import { Checkbox as ACheckbox } from 'ant-design-vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

@Component({
  components: {
    Button,
    ACheckbox,
    VuePerfectScrollbar,
  },
})
export default class FiltersSingle extends Vue {
  @Prop({ required: true, default: null }) label!: string;
  @Prop({ required: true, default: null }) options!: IEventsFiltersSingle[] | any;
  @Prop({ required: true, default: null }) type!: IFiltersTypes;
  @Prop({ required: false, default: false }) isSingleCourse?: boolean;

  @Emit()
  submitFilters({ clear }: { clear: boolean }) {
    return this.submitState({ clear });
  }

  @Watch('options', { immediate: true, deep: true })
  private onOptionsChanged() {
    this.isLoading = true;
    setTimeout(() => (this.isLoading = false), 500);
  }

  private isDropdownVisible = false;
  private IButtonType = IButtonType;
  private IButtonSize = IButtonSize;
  private IButtonColor = IButtonColor;
  private selected: IFilterState | null = null;
  private selectedClear = false;
  private selectedCount?: number = 0;
  private selectedNameActive = false;
  private name = this.$t('components.filters.selectDefault').toString();
  private searchShow = false;
  private searchText = '';
  private searchOptions?: IEventsFiltersSingle[] = [];
  private clearOptions?: any = [];
  public getOptions: IEventsFiltersSingle[] = [];
  public isLoading = false;

  private created() {
    this.selected = { type: this.type, options: [] };

    if (this.options.length > 6) {
      this.searchShow = true;
    }
  }

  private onSearch() {
    this.searchOptions = this.options.filter((option: IEventsFiltersSingle) => {
      return (
        (option.name &&
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.searchText.toLowerCase()) >= 0) ||
        (option.title &&
          option.title
            .toString()
            .toLowerCase()
            .indexOf(this.searchText.toLowerCase()) >= 0)
      );
    });
  }

  private async submitState({ clear }: { clear: boolean }): Promise<any> {
    this.isDropdownVisible = false;
    this.selectedCount = clear ? 0 : this.selected?.options.length;
    this.selectedNameActive = !!this.selectedCount;
    this.name = this.selectedName;
    this.selected = clear ? { type: this.type, options: [] } : this.selected;

    if (clear) {
      this.selectedClear = true;
      this.searchOptions = [];
      this.searchText = '';
      setTimeout(() => (this.selectedClear = false), 300);
      this.isDropdownVisible = false;
    }

    return { ...this.selected, clear };
  }

  private onChange(item: IEventsFiltersSingle): void {
    const object = this.selected?.options.find(obj => {
      if (obj.id) {
        return obj.id === item.id;
      }
      return obj.idString === item.idString;
    });

    if (object && this.selected) {
      this.selected.options = this.selected?.options.filter((obj: IEventsFiltersSingle) => {
        return obj.id !== object.id || obj.idString !== object.idString;
      });
      return;
    }
    this.selected?.options.push(item);
  }

  private get selectedName(): string {
    if (!this.selectedCount) {
      this.selectedNameActive = false;
      return this.$t('components.filters.selectDefault').toString();
    }

    if (!this.selected) {
      return '';
    }

    const object: IEventsFiltersSingle = this.selected?.options[this.selectedCount - 1];
    const name: string = object?.title ? object?.title : object?.name ? object?.name : '';

    if (this.selectedCount === 1) {
      return name;
    }
    this.selectedNameActive = true;
    return `${name}${this.$t('components.filters.more', { count: this.selectedCount - 1 }).toString()}`;
  }
}
