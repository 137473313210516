import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './lang/en.json';
import fr from './lang/fr.json';
import store from '@/store';

Vue.use(VueI18n);

export default new VueI18n({
  locale: store.getters['Languages/getCurrent'] || process.env.VUE_APP_DEFAULT_LOCALE,
  silentTranslationWarn: process.env.NODE_ENV === 'production',
  messages: {
    en,
    fr,
  },
});
