






















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { IEventsSingle, IEventStatus } from '@/types/events';
import { capitalize } from '@/helpers/capitalize';
import { eventDatetime } from '@/helpers/eventDatetime';
import Button from '@/components/Button.vue';
import { IButtonType, IButtonSize } from '@/types/buttons';
import Modal from '@/components/Modal.vue';
import EventsRegister from './EventsRegister.vue';

@Component({
  components: {
    Button,
    Modal,
    EventsRegister,
  },
})
export default class EventsCard extends Vue {
  @Prop({ required: false, default: false }) event!: IEventsSingle;

  private capitalize = capitalize;
  private eventDatetime = eventDatetime;
  private IButtonType = IButtonType;
  private IButtonSize = IButtonSize;

  private get isConfirmed(): boolean {
    if (!this.event) {
      return false;
    }
    return this.event.status === IEventStatus.CONFIRMED ? true : false;
  }
}
