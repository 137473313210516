import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { name, version } from '../../../package.json';

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    release: `${name}@${version}`,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
  });
}
