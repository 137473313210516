












import { Component, Watch, Vue } from 'vue-property-decorator';
import Header from '@/components/Header.vue';
import { metaTitles, metaDescriptions, metaKeywords, metaImage, metaLink, metaStatic } from '@/helpers/vueMeta';
import { Getter } from 'vuex-class';
import Footer from '@/components/Footer.vue';
import { Route } from 'vue-router';
import insertCookieBot from '@/3rd-libraries/gtm-automatic-cookie-blocking';
import insertZohoScript from '@/3rd-libraries/zoho-chat';

@Component({
  metaInfo(this: Vue & { thumbnail: string }): any {
    const domain: string = this.$t('global.domain').toString();
    return {
      titleTemplate: (titleChunk: string) => (titleChunk ? `${titleChunk} • ${domain}` : domain),
      meta: [...metaTitles(), ...metaDescriptions(), ...metaKeywords(), ...metaImage(this.thumbnail), ...metaLink(), ...metaStatic()],
    };
  },
  components: {
    Header,
    Footer,
  },
})
export default class Home extends Vue {
  @Getter('BodyBackground/getGrey') isGrey!: boolean;
  @Getter('Thumbnail/getCurrent') thumbnailImage!: string;

  @Watch('$route', { immediate: true, deep: true })
  private async onChanged(route: Route) {
    if (route.name === 'Search') {
      this.showLayout = false;
    } else {
      this.showLayout = true;
    }
  }

  @Watch('thumbnailImage', { immediate: true, deep: true })
  private async onChangedThumbnail() {
    this.thumbnail = this.thumbnailImage;
  }

  mounted() {
    insertCookieBot();
    insertZohoScript();
  }

  public showLayout = true;
  public thumbnail = '';
}
