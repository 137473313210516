
















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class CardsWithIcons extends Vue {
  @Prop({ required: true, default: null }) icon!: string;
  @Prop({ required: true, default: null }) name!: string;
  @Prop({ required: true, default: null }) content!: string;
}
