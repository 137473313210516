























































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import PageBreadcrumbs from '@/components/PageBreadcrumbs.vue';
import PageContainer from '@/components/PageContainer.vue';
import PageTitle from '@/components/PageTitle.vue';
import { MetaInfo } from 'vue-meta';
import PageDescription from '@/components/PageDescription.vue';
import { venue as venueFetch } from '@/service/api/module/venue';
import { IVenue } from '@/types/venue';
import { Getter } from 'vuex-class';
import { ILanguage } from '@/types/language';

@Component({
  metaInfo(this: Vue & { $t: string }): MetaInfo {
    return {
      title: this.$t('views.venue.title').toString(),
    };
  },
  components: {
    PageContainer,
    PageBreadcrumbs,
    PageTitle,
    PageDescription,
  },
})
export default class Venue extends Vue {
  @Getter('Languages/getCurrent') language!: ILanguage;

  public venue?: IVenue | null = null;
  public mapsLink =
    'https://www.google.com/maps/embed/v1/place?key=AIzaSyB9X6HNnGy47jAR3Vpnjg6q0a3NaFjeB0E&q=Abilene+Academy+Rue+des+Charpentiers+24A+1110+Morges+Switzerland';

  private async created() {
    this.venue = await venueFetch();
  }
}
