















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Carousel, Slide } from 'vue-carousel';

@Component({
  components: {
    Carousel,
    Slide,
  },
})
export default class PageCarousel extends Vue {
  @Prop({ required: false, default: 1 }) perPage?: number;
  @Prop({ required: false, default: () => [] }) perPageCustom?: Array<[]>;
}
