import { render, staticRenderFns } from "./Testimonial.vue?vue&type=template&id=5b73c6e9&scoped=true&"
import script from "./Testimonial.vue?vue&type=script&lang=ts&"
export * from "./Testimonial.vue?vue&type=script&lang=ts&"
import style0 from "./Testimonial.vue?vue&type=style&index=0&id=5b73c6e9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b73c6e9",
  null
  
)

export default component.exports