









































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { coursesCategorySingle } from '@/service/api/module/course';
import { ICourseSingle, ICoursesCategoriesSingle } from '@/types/courses';
import PageBreadcrumbs from '@/components/PageBreadcrumbs.vue';
import Button from '@/components/Button.vue';
import PageContainer from '@/components/PageContainer.vue';
import PageTitle from '@/components/PageTitle.vue';
import Modal from '@/components/Modal.vue';
import { permalink } from '@/helpers/permalink';
import { coursesSingle, courseTestimonial } from '@/service/api/module/course';
import { IEventsSingle } from '@/types/events';
import { courseClosestEvent } from '@/service/api/module/events';
import CoursesOverviewSidebar from '@/components/CoursesOverviewSidebar.vue';
import { IButtonType, IButtonSize, IButtonColor } from '@/types/buttons';
import PageTags from '@/components/PageTags.vue';
import AnchorTabs from '@/components/AnchorTabs.vue';
import EventsCardSidebar from '@/components/EventsCardSidebar.vue';
import { Route } from 'vue-router';
import Filters from '@/components/Filters.vue';
import { MetaInfo } from 'vue-meta';
import Loading from '@/components/Loading.vue';
import { IEventsFilters } from '@/types/events';
import EventsRegister from '@/components/EventsRegister.vue';
import FeedbackCarousel from '@/components/FeedbackCarousel.vue';


@Component({
  metaInfo(this: Vue & { $t: string; course: ICourseSingle; category: ICoursesCategoriesSingle }): MetaInfo {
    return {
      title: this.$t('views.courses.single.titleSeo', { courseName: this.course?.title, categoryName: this.category?.name }).toString(),
    };
  },
  components: {
    PageBreadcrumbs,
    Button,
    PageContainer,
    PageTitle,
    CoursesOverviewSidebar,
    PageTags,
    AnchorTabs,
    EventsCardSidebar,
    Filters,
    Loading,
    Modal,
    EventsRegister,
    FeedbackCarousel
  },
})
export default class CursesSingle extends Vue {
  @Watch('$route', { immediate: true, deep: true })
  private async onChanged(route: Route) {
    const courseId = Number(route.params.id);
    if (!courseId) {
      return this.$router.push({ name: 'Home' });
    }
    this.getCourse({ id: courseId });
  }

  mounted() {
    this.getCourseTestimonial();
  }

  private course: ICourseSingle | null = null;
  private courseId!: number;
  private coursesEvents: IEventsSingle[] | null = null;
  private coursesEventsFilters: IEventsSingle[] | null = null;
  private category: ICoursesCategoriesSingle | null = null;
  private categoryId?: number;
  private categorySlug?: string;

  private IButtonType = IButtonType;
  private IButtonSize = IButtonSize;
  private IButtonColor = IButtonColor;
  public isLoading = true;
  public filters?: IEventsFilters | null = null;
  private testimonials = [];

  private submitObjectFilters(events: IEventsSingle[] | null) {
    this.coursesEventsFilters = events?.slice(0, 3) || [];
  }

  private async getCourseTestimonial() {
    const response = await courseTestimonial({ id: this.courseId });
    this.testimonials = response.items;
  }

  private async getCourse({ id }: { id: number }) {
    this.courseId = id;
    const course = await coursesSingle({ id: this.courseId });
    if (!course.items.length || !course.items[0].categories.length) {
      return this.$router.push({ name: 'Home' });
    }

    this.course = course.items[0];
    if (!this.course) {
      return this.$router.push({ name: 'Home' });
    }

    // validating for lang
    const { params } = this.$route;
    const { meta } = this.course;
    if (!params || Number(params.id) !== Number(this.course.id)) {
      return this.$router.replace({ params: { id: this.course.id.toString(), slug: meta.slug } });
    }

    this.filters = course.filters;

    this.category = await coursesCategorySingle({ id: this.course.categories[0].id , locale: this.$route.params.lang});
    this.categoryId = this.category?.id;
    this.categorySlug = permalink({ text: this.category?.name });

    const coursesEvents = await courseClosestEvent({ id: this.courseId, customOrder: true });
    this.coursesEvents = coursesEvents?.slice(0, 3) || [];

    this.isLoading = false;
  }

  private get backgroundImage(): string {
    if (!this.course?.hero_image) {
      return require('@/assets/images/background/frame98.png');
    }
    return this.course.hero_image;
  }

  private get tabsContent() {
    if (!this.course) {
      return null;
    }

    const tabs = [];
    if (this.course.overview) {
      tabs.push({ name: this.$t('components.page.tabs.menu.overview').toString(), content: this.course.overview });
    }

    if (this.course.program) {
      tabs.push({ name: this.$t('components.page.tabs.menu.program').toString(), content: this.course.program });
    }

    if (this.course.exam_and_certificate) {
      tabs.push({ name: this.$t('components.page.tabs.menu.examCertificate').toString(), content: this.course.exam_and_certificate });
    }

    if (this.course.educational_approach) {
      tabs.push({ name: this.$t('components.page.tabs.menu.educationalApproach').toString(), content: this.course.educational_approach });
    }

    tabs.push({
      isFeedback: true,
      name: this.$t('components.page.tabs.menu.feedback').toString(),
      slides: this.testimonials
    });

    return tabs;
  }
}
