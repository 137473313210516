








































import { Component, Prop, Vue } from 'vue-property-decorator';
import Button from '@/components/Button.vue';
import { IButtonType, IButtonSize, IButtonColor } from '@/types/buttons';
import { INewsPost } from '@/types/news';
import { monthCapitalize } from '@/helpers/dateFns';

@Component({
  components: {
    Button,
  },
})
export default class NewsPostCard extends Vue {
  @Prop({ required: true, default: null }) post!: INewsPost | null;

  public IButtonType = IButtonType;
  public IButtonSize = IButtonSize;
  public IButtonColor = IButtonColor;

  public get dateTime() {
    if (!this.post) {
      return '';
    }
    const date = this.post.date.toString();
    const [year, month, day] = date.split('-');
    return `${monthCapitalize({ date: this.post.date })} ${day}, ${year}`;
  }
}
