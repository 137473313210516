











































import { Component, Prop, Vue } from 'vue-property-decorator';
import Carousel from '@/components/Carousel.vue';
import { Slide } from 'vue-carousel';
import { IHomepagePartner } from '@/types/homepage';

@Component({
  components: {
    Carousel,
    Slide,
  },
})
export default class HomepagePartners extends Vue {
  @Prop({ required: true, default: null }) partners?: IHomepagePartner[];
}
