import axios from '../axios';
import { AxiosResponse, AxiosError } from 'axios';
import { ICookies, ICookie } from '@/types/cookies';
import store from '@/store';

const COOKIES_URI = `api/v2/pages/?type=academy.CookiesPolicyIndexPage&fields=*&locale=${store.getters['Languages/getCurrent']}`;

export const cookies = async (): Promise<ICookie | null> => {
  return await axios
    .get(COOKIES_URI)
    .then((response: AxiosResponse) => {
      const { items }: ICookies = response.data;
      return items[0];
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return null;
    });
};
