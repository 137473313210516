


















































import { Component, Vue } from 'vue-property-decorator';
import PageBreadcrumbs from '@/components/PageBreadcrumbs.vue';
import PageContainer from '@/components/PageContainer.vue';
import PageTitle from '@/components/PageTitle.vue';
import PageDescription from '@/components/PageDescription.vue';
import CardsWithIcons from '@/components/CardsWithIcons.vue';
import Loading from '@/components/Loading.vue';
import AppCard from "@/components/AppCard.vue"
import { academyTestimonials as academyTestimonialsFetch } from '@/service/api/module/academy';
import Testimonial from "@/components/Testimonial.vue";
import {ITestimonialPage} from "@/types/testimonials";
import {MetaInfo} from "vue-meta";

@Component({
  metaInfo(this: Vue & { $t: string; testimonialsPage: ITestimonialPage }): MetaInfo {
    return {
      title: this.testimonialsPage && this.testimonialsPage.title ? this.testimonialsPage.title : '',
    };
  },
  components: {
    Testimonial,
    PageContainer,
    PageBreadcrumbs,
    PageTitle,
    PageDescription,
    CardsWithIcons,
    Loading,
    AppCard
  },
})
export default class AcademyTestimonials extends Vue {
  public testimonialsPage?: ITestimonialPage | null = null;
  public isLoading = true;

  private async created() {
    const [testimonialsPage] = await Promise.all([ academyTestimonialsFetch(this.$route.params.lang)]);
    this.isLoading = false;
    this.testimonialsPage = testimonialsPage;
  }

}
