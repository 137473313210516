import validator from 'validator';
import i18n from '@/locales';

const trans = (str: string, obj?: object): string => i18n.t(str, obj).toString();

const options = {
  fullName: {
    min: 3,
    max: 40,
  },
  organization: {
    min: 3,
    max: 40,
  },
  email: {
    min: 6,
    max: 255,
  },
  message: {
    min: 6,
    max: 255,
  },
};

const validatorForm = {
  fullName: (str: string | false): string | void => {
    if (!str) {
      return trans('helpers.validator.fullName.notEmpty');
    } else if (!validator.isLength(str, { min: options.fullName.min, max: options.fullName.max })) {
      return trans('helpers.validator.fullName.length', { min: options.fullName.min, max: options.fullName.max });
    }
  },
  organization: (str: string | false): string | void => {
    if (!str) {
      return trans('helpers.validator.organization.notEmpty');
    } else if (!validator.isLength(str, { min: options.organization.min, max: options.organization.max })) {
      return trans('helpers.validator.organization.length', { min: options.organization.min, max: options.organization.max });
    }
  },
  email: (str: string | false): string | void => {
    if (!str) {
      return trans('helpers.validator.email.notEmpty');
    } else if (!validator.isEmail(str)) {
      return trans('helpers.validator.email.isEmail');
    } else if (!validator.isLength(str, { min: options.email.min, max: options.email.max })) {
      return trans('helpers.validator.email.length', { min: options.email.min, max: options.email.max });
    }
  },
  message: (str: string | false): string | void => {
    if (!str) {
      return trans('helpers.validator.message.notEmpty');
    } else if (!validator.isLength(str, { min: options.message.min, max: options.message.max })) {
      return trans('helpers.validator.message.length', { min: options.message.min, max: options.message.max });
    }
  },
  checkbox: (str: string | boolean): string | void => {
    if (!str || str === '') {
      return trans('helpers.validator.checkbox.notEmpty');
    }
  },
};

export default validatorForm;
