import Vue from 'vue';
import VueRouter, { Route, RouteConfig, NavigationGuardNext } from 'vue-router';
import HomepageHome from '@/views/homepage/Home.vue';

import { CoursesRoute } from './module/courses';
import { ScheduleRoute } from './module/schedule';
import { AcademyRoute } from './module/academy';
import { ClientsRoute } from './module/clients';
import { ContactRoute } from './module/contact';
import { ExpirienceRoute } from './module/expirience';
import { NewsRoute } from './module/news';
import { CookiesRoute } from './module/cookies';

import store from '@/store';
import i18n from '@/locales';

declare global {
  interface Window { vgo: any}
}

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/:lang',
    name: 'Home',
    component: HomepageHome,
  },
  {
    path: '/:lang/search',
    name: 'Search',
    props: (route: Route) => ({ search: route.query.search }),
    component: () => import('@/views/Search.vue'),
  },
  {
    path: '/:lang/privacy-policy',
    name: 'PrivacyPolicy',
    props: (route: Route) => ({ search: route.query.search }),
    component: () => import('@/views/PrivacyPolicy.vue'),
  },
  ...CoursesRoute,
  ...ScheduleRoute,
  ...AcademyRoute,
  ...ClientsRoute,
  ...ContactRoute,
  ...NewsRoute,
  ...CookiesRoute,
  ...ExpirienceRoute,
  {
    path: '*',
    beforeEnter() {
      window.location.replace('/');
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  routes,

  scrollBehavior(to: Route, from: Route, savedPosition: { x: number; y: number } | void) {
    if (to.matched.some(m => m.meta.disableScroll)) {
      return;
    }
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.afterEach( () => {
  window.vgo('update')
})

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
  const hasLang = to.params && to.params.lang;
  const hadLang = from.params && from.params.lang;

  // If the language hasn't changed since last route we're done
  if (hasLang && hadLang && from.params.lang.toLowerCase() === to.params.lang.toLowerCase()) {
    next();
  }

  // Get the save language if it exists
  let lang = store.getters['Languages/getCurrent'] || process.env.VUE_APP_DEFAULT_LOCALE;

  // Overwrite the language with the route if there is one
  if (hasLang) {
    lang = to.params.lang.toLowerCase();
  }

  // Make sure the language is valid
  if (!['en', 'fr'].includes(lang)) {
    lang = 'en';
  }

  // Set the website language based on the URL
  i18n.locale = lang;
  store.commit('Languages/setLanguage', { lang });

  // Redirect to a url with the language
  if (!hasLang) {
    to.params.lang = lang;
    // return next(to.fullPath);
    return next(`/${lang}${to.fullPath}`);
  }
  return next();

  // if (!hasLang) {
  //   // console.log('/${locale}${to.fullPath} => ', `/${locale}${to.fullPath}`);
  //   return next({ path: `/${locale}${to.fullPath}`, replace: true });
  // }

  // console.log('nie powinno mnie tu byc');

  return next();
  // Evaluate whether or not the URL contains a language
  // const hasLang = to.params && to.params.lang;
  // const hadLang = from.params && from.params.lang;

  // // If the language hasn't changed since last route we're done
  // if (hasLang && hadLang && from.params.lang.toLowerCase() === to.params.lang.toLowerCase()) {
  //   next();
  // }

  // // Get the save language if it exists
  // let lang = store.getters['Languages/getCurrent'] || process.env.VUE_APP_DEFAULT_LOCALE;

  // // Overwrite the language with the route if there is one
  // if (hasLang) {
  //   lang = to.params.lang.toLowerCase();
  // }

  // // Make sure the language is valid
  // if (!['en', 'fr'].includes(lang)) {
  //   lang = 'en';
  // }

  // // Set the website language based on the URL
  // i18n.locale = lang;
  // store.commit('Languages/setLanguage', { lang });

  // // Redirect to a url with the language
  // if (!hasLang) {
  //   return next(`/${lang}${to.fullPath}`);
  // }
  // return next();
});

export default router;
