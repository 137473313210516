













































import { Component, Prop, Vue } from 'vue-property-decorator';
import { IHomepageFlayer } from '@/types/homepage';
import { eventDatetimeHomepage } from '@/helpers/eventDatetime';
import Modal from '@/components/Modal.vue';
import Button from '@/components/Button.vue';
import EventsRegister from '@/components/EventsRegister.vue';
import { IButtonType, IButtonSize } from '@/types/buttons';

@Component({
  components: {
    Modal,
    Button,
    EventsRegister,
  },
})
export default class HomepageFlayer extends Vue {
  @Prop({ required: true, default: null }) flayer!: IHomepageFlayer;
  @Prop({ required: false, default: null }) white!: boolean;

  public eventDatetimeHomepage = eventDatetimeHomepage;
  public IButtonType = IButtonType;
  public IButtonSize = IButtonSize;

  public get location(): string {
    let trainings = '';
    for (const training of this.flayer?.event_details?.training_format) {
      trainings += `${training.name} +`;
    }
    trainings = trainings ? trainings.substring(0, trainings.length - 1) : trainings;
    const { city, country } = this.flayer?.event_details?.location;
    return `${trainings ? trainings + ' ' : ''} ${city} ${country ? ' - ' + country : ''}`;
  }
}
