


































































































import { Component, Vue } from 'vue-property-decorator';
import PageBreadcrumbs from '@/components/PageBreadcrumbs.vue';
import PageContainer from '@/components/PageContainer.vue';
import PageTitle from '@/components/PageTitle.vue';
import { MetaInfo } from 'vue-meta';
import PageDescription from '@/components/PageDescription.vue';
import {
  trainingDelivery as trainingDeliveryFetch,
  trainingFormat as trainingFormatFetch,
} from '@/service/api/module/trainingDeliveryOptions';
import { ITrainingDeliver, ITrainingFormat } from '@/types/training';
import Loading from '@/components/Loading.vue';

@Component({
  metaInfo(this: Vue & { $t: string }): MetaInfo {
    return {
      title: this.$t('views.trainingDeliveryOptions.title').toString(),
    };
  },
  components: {
    PageContainer,
    PageBreadcrumbs,
    PageTitle,
    PageDescription,
    Loading,
  },
})
export default class TrainingDeliveryOptions extends Vue {
  public trainingDelivery: ITrainingDeliver | null = null;
  public trainingFormats: ITrainingFormat[] | null = null;
  public isLoading = true;

  private async created() {
    const [trainingDelivery, trainingFormats] = await Promise.all([trainingDeliveryFetch(), trainingFormatFetch()]);
    this.trainingDelivery = trainingDelivery;
    this.trainingFormats = trainingFormats;
    this.isLoading = false;
  }

  public cardIcons({ index }: { index: number }): string {
    return index === 0 || index === 2
      ? require('@/assets/images/icons/shield-silver.svg')
      : require('@/assets/images/icons/globe-orange.svg');
  }

  public get backgroundImage(): string {
    if (!this.trainingDelivery?.hero_image) {
      return require('@/assets/images/background/courseCategory.jpg');
    }
    return this.trainingDelivery.hero_image;
  }

  public scrollTo(name: string) {
    const element: any = this.$refs[name];
    if (element && element[0]) {
      element[0].scrollIntoView({ behavior: 'smooth' });
    }
  }
}
