



























































































































































import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import HeaderMenu from '@/components/HeaderMenu.vue';
import HamburgerButton from '@/components/HamburgerButton.vue';
import SearchButton from '@/components/SearchButton.vue';
import { ILanguage } from '@/types/language';

@Component({
  components: {
    HeaderMenu,
    HamburgerButton,
    SearchButton,
  },
})
export default class Sidenav extends Vue {
  @Action('Languages/updateLanguage') updateLanguage!: any;
  @Getter('Languages/getCurrent') languageActive!: ILanguage;
  @Action('SidenavExtend/close') close!: typeof Boolean;

  private language = [ILanguage.ENGLISH.toString(), ILanguage.FRENCH.toString()];

  public switchLanguage(e: any) {
    const lang = this.language[e.key];
    this.updateLanguage({ lang });
    this.$router.replace({ params: { lang } });
    return this.$router.go(0);
  }
  private closeSidenav() {
    this.close();
  }
  private goToPage(name: string) {
    this.$router.push({ name });
    this.close();
  }
}
