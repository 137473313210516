import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { ILanguage } from '@/types/language';
import i18n from '@/locales';

@Module({ namespaced: true })
class Languages extends VuexModule {
  private current: ILanguage = ILanguage.ENGLISH;

  public get getCurrent(): ILanguage {
    return this.current;
  }

  @Mutation setLanguage({ lang }: { lang: ILanguage }): void {
    this.current = lang;
  }

  @Action updateLanguage({ lang }: { lang: ILanguage }): void {
    this.context.commit('setLanguage', { lang });
    i18n.locale = lang;
  }
}
export { Languages };
