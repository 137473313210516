import axios from '../axios';
import { AxiosResponse, AxiosError } from 'axios';
import {
  IHomepageClient,
  IHomepageClients,
  IHomepageFields,
  IHomepageField,
  IHomepageFlayer,
  IHomepageFlayers,
  IHomepageBestSeller,
  IHomepageBestSellers,
  IHomepageTestimonials,
  IHomepageTestimonial,
  IHomepagePartners,
  IHomepagePartner,
} from '@/types/homepage';
import store from '@/store';

const HOMEPAGE_ITEMS_URI = `api/v2/pages/?type=home.HomeIndexPage&fields=*&locale=${store.getters['Languages/getCurrent']}`;
const HOMEPAGE_FLAYERS_URI = `api/v2/flyer/?locale=${store.getters['Languages/getCurrent']}`;
const HOMEPAGE_BESTSELLER_URI = `api/v2/pages/?type=course.CoursePage&best_seller=true&locale=${store.getters['Languages/getCurrent']}`;
const HOMEPAGE_TESTIMONIAL_URI = `api/v2/testimonial/?limit=50&locale=${store.getters['Languages/getCurrent']}&main_page=true`;
const HOMEPAGE_PARTNERS_URI = `api/v2/partner/?locale=${store.getters['Languages/getCurrent']}`;
const HOMEPAGE_CLIENTS_URI = `api/v2/client/?limit=100&locale=${store.getters['Languages/getCurrent']}`;

const homepageItems = async (): Promise<IHomepageField | null> => {
  return await axios
    .get(HOMEPAGE_ITEMS_URI)
    .then((response: AxiosResponse) => {
      const { items }: IHomepageFields = response.data;
      return items[0];
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return null;
    });
};

const homepageFlayers = async (): Promise<IHomepageFlayer[] | null> => {
  return await axios
    .get(HOMEPAGE_FLAYERS_URI)
    .then((response: AxiosResponse) => {
      const { items }: IHomepageFlayers = response.data;
      return items;
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return null;
    });
};

const homepageBestSellers = async (): Promise<IHomepageBestSeller[] | null> => {
  return await axios
    .get(HOMEPAGE_BESTSELLER_URI)
    .then((response: AxiosResponse) => {
      const { items }: IHomepageBestSellers = response.data;
      return items;
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return null;
    });
};

const homepageTestimonials = async (): Promise<IHomepageTestimonial[] | null> => {
  return await axios
    .get(HOMEPAGE_TESTIMONIAL_URI)
    .then((response: AxiosResponse) => {
      const { items }: IHomepageTestimonials = response.data;
      return items;
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return null;
    });
};

const homepagePartners = async (): Promise<IHomepagePartner[] | null> => {
  return await axios
    .get(`${HOMEPAGE_PARTNERS_URI}&order=name`)
    .then((response: AxiosResponse) => {
      const { items }: IHomepagePartners = response.data;
      return items;
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return null;
    });
};

const homepageClients = async (): Promise<IHomepageClient[] | null> => {
  return await axios
    .get(HOMEPAGE_CLIENTS_URI)
    .then((response: AxiosResponse) => {
      const { items }: IHomepageClients = response.data;
      return items;
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return null;
    });
};

export { homepageItems, homepageFlayers, homepageBestSellers, homepageTestimonials, homepagePartners, homepageClients };
