export default function() {
  // https://support.cookiebot.com/hc/en-us/articles/360009192739-Google-Tag-Manager-and-Automatic-cookie-blocking

  const headTag = document.getElementsByTagName('head')[0];
  const googleConsentMode = document.createElement('script');
  googleConsentMode.dataset.cookieconsent = 'ignore';
  googleConsentMode.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments)
    };
    gtag("consent", "default", {
      ad_storage: "denied",
      analytics_storage: "denied",
      functionality_storage: "denied",
      personalization_storage: "denied",
      security_storage: "granted",
      wait_for_update: 500
    });
    gtag("set", "ads_data_redaction", true);
    gtag("set", "url_passthrough", true);
  `;
  
  const cookiebotCMP = document.createElement('script');
  cookiebotCMP.setAttribute('id', 'Cookiebot');
  cookiebotCMP.setAttribute('src', 'https://consent.cookiebot.com/uc.js');
  cookiebotCMP.setAttribute('type', 'text/javascript');
  cookiebotCMP.dataset.cbid = process.env.VUE_APP_COOKIEBOT_API_KEY;
  cookiebotCMP.dataset.blockingmode = 'auto';

  headTag.appendChild(googleConsentMode);
  headTag.appendChild(cookiebotCMP);
}
