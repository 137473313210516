import { IEventsFiltersSingle } from './events';

export enum IFiltersTypes {
  Category,
  Course,
  Language,
  TrainingFormat,
  Location,
  Date,
}

export type IFiltersTypesQuery = {
  category?: string;
  course?: string;
  language?: string;
  location?: string;
  datetime?: string;
  training_formats?: string;
};

export type IFilterState = {
  type: IFiltersTypes;
  options: IEventsFiltersSingle[];
};
