
























































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import FiltersSingle from './FiltersSingle.vue';
import { IEventsFilters, IEventsFiltersSingle } from '@/types/events';
import { IFiltersTypes, IFiltersTypesQuery } from '@/types/filters';
import { coursesByFilters } from '@/service/api/module/course';
import { eventsByFilters } from '@/service/api/module/events';
import { monthRange } from '@/helpers/dateFns';
import { Action, Getter } from 'vuex-class';

@Component({
  components: {
    FiltersSingle,
  },
})
export default class Filters extends Vue {
  @Prop({ required: true, default: null }) filters!: IEventsFilters;
  @Prop({ required: false, default: true }) showCategories?: boolean;
  @Prop({ required: false, default: null }) categoryId?: number;
  @Prop({ required: false, default: false }) isSingleCourse?: boolean;
  @Prop({ required: false, default: false }) isEvents?: boolean;
  @Prop({ required: false, default: false }) isSearch?: boolean;
  @Prop({ required: false, default: false }) searchText?: string;
  @Action('Filters/updateFilters') updateFilters!: any;
  @Getter('Filters/getCurrent') getFiltersState!: IEventsFilters;

  @Watch('filters', { immediate: true, deep: true })
  private filtersChanged(newVal: IEventsFilters) {
    this.queryFilters = newVal;
  }

  private IFiltersTypes!: IFiltersTypes;
  private query: IFiltersTypesQuery = {};
  public queryFilters: IEventsFilters | null = null;
  public isLoading = false;

  private async created() {
    this.queryFilters = this.filters;
    this.updateFilters({ filters: this.queryFilters });
  }

  private get options(): { label: string; type: IFiltersTypes; options: IEventsFiltersSingle[] }[] | null {
    if (!this.queryFilters) {
      return null;
    }

    let options = [
      {
        label: this.$t('components.filters.select.category').toString(),
        type: IFiltersTypes.Category,
        options: this.queryFilters.categories,
      },
      {
        label: this.$t('components.filters.select.course').toString(),
        type: IFiltersTypes.Course,
        options: this.queryFilters.courses,
      },
      {
        label: this.$t('components.filters.select.language').toString(),
        type: IFiltersTypes.Language,
        options: this.queryFilters.languages,
      },
      {
        label: this.$t('components.filters.select.trainingFormat').toString(),
        type: IFiltersTypes.TrainingFormat,
        // eslint-disable-next-line @typescript-eslint/camelcase
        options: this.queryFilters.training_formats,
      },
      {
        label: this.$t('components.filters.select.location').toString(),
        type: IFiltersTypes.Location,
        options: this.queryFilters.locations,
      },
      {
        label: this.$t('components.filters.select.date').toString(),
        type: IFiltersTypes.Date,
        options: this.filtersDatetime,
      },
    ];

    if (!this.showCategories) {
      options = options.slice(1);
    }

    if (this.isSingleCourse) {
      options = options.slice(2);
    }

    return options;
  }

  private async submitFilters(value: { type: IFiltersTypes; options: IEventsFiltersSingle[]; clear: boolean }) {
    this.isLoading = true;

    const ids: string = value.options.map((item: IEventsFiltersSingle) => item.id).join(',');
    const idsString: string = value.options.map((item: IEventsFiltersSingle) => item.idString).join(',');

    if (!this.showCategories) {
      this.query.category = this.categoryId?.toString();
    } else {
      value.type === IFiltersTypes.Category ? (this.query.category = ids || '') : '';
    }

    value.type === IFiltersTypes.Course ? (this.query.course = ids || '') : '';
    value.type === IFiltersTypes.Location ? (this.query.location = ids || '') : '';
    value.type === IFiltersTypes.Language ? (this.query.language = ids || '') : '';
    // eslint-disable-next-line @typescript-eslint/camelcase
    value.type === IFiltersTypes.TrainingFormat ? (this.query.training_formats = ids || '') : '';
    value.type === IFiltersTypes.Date ? (this.query.datetime = idsString || '') : '';

    const { options } = value;
    let filters;
    let courses;
    let events;

    if (this.isSingleCourse || this.isEvents) {
      const responseEvents = await eventsByFilters({
        ...(this.isSingleCourse && { customOrder: true }),
        params: {
          ...this.query,
          ...(this.isSingleCourse && { course: this.getFiltersState.courses[0].id as any }),
        },
      });
      filters = responseEvents.filters;
      events = responseEvents.events;
    } else {
      const searchText = !this.searchText || this.searchText.length <= 3 ? '' : this.searchText;
      const responseCourses = await coursesByFilters({ params: this.query, searchText });
      filters = responseCourses.filters;
      courses = responseCourses.courses;
    }

    if ((events || courses) && filters) {
      if (this.queryFilters && value.options && value.options.length) {
        switch (value.type) {
          case IFiltersTypes.Category:
            if (filters.categories.length) {
              this.queryFilters.categories = this.slected({ selectedOptions: options, filters: filters.categories });
            }
            break;
          case IFiltersTypes.Course:
            if (filters.courses.length) {
              this.queryFilters.courses = this.slected({ selectedOptions: options, filters: filters.courses });
            }
            break;
          case IFiltersTypes.Language:
            if (filters.languages.length) {
              this.queryFilters.languages = this.slected({ selectedOptions: options, filters: filters.languages });
            }
            break;
          case IFiltersTypes.TrainingFormat:
            if (filters.training_formats.length) {
              // eslint-disable-next-line @typescript-eslint/camelcase
              this.queryFilters.training_formats = this.slected({ selectedOptions: options, filters: filters.training_formats });
            }
            break;
          case IFiltersTypes.Location:
            if (filters.locations.length) {
              this.queryFilters.locations = this.slected({ selectedOptions: options, filters: filters.locations });
            }
            break;
          case IFiltersTypes.Date:
            this.queryFilters.datetime = this.filtersDatetime;
            break;
        }
      } else {
        this.queryFilters = filters;
      }

      if (this.queryFilters && this.getFiltersState) {
        if (value.type !== IFiltersTypes.Category && this.queryFilters.categories.length && filters.categories.length) {
          this.queryFilters.categories = this.slectedState({
            selectedOptions: this.getFiltersState.categories,
            filters: filters.categories,
          });
        }
        if (value.type !== IFiltersTypes.Course && this.queryFilters.courses.length && filters.courses.length) {
          this.queryFilters.courses = this.slectedState({
            selectedOptions: this.getFiltersState.courses,
            filters: filters.courses,
          });
        }
        if (value.type !== IFiltersTypes.Language && this.queryFilters.languages.length && filters.languages.length) {
          this.queryFilters.languages = this.slectedState({
            selectedOptions: this.getFiltersState.languages,
            filters: filters.languages,
          });
        }
        if (value.type !== IFiltersTypes.TrainingFormat && this.queryFilters.training_formats.length && filters.training_formats.length) {
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.queryFilters.training_formats = this.slectedState({
            selectedOptions: this.getFiltersState.training_formats,
            filters: filters.training_formats,
          });
        }
        if (value.type !== IFiltersTypes.Location && this.queryFilters.locations.length && filters.locations.length) {
          this.queryFilters.locations = this.slectedState({
            selectedOptions: this.getFiltersState.locations,
            filters: filters.locations,
          });
        }
      }
    }

    if (this.isSingleCourse || this.isEvents) {
      this.$emit('submit-object-filters', events);
    } else {
      this.$emit('submit-object-filters', courses);
    }

    this.updateFilters({ filters: this.queryFilters });
    this.isLoading = false;
  }

  private slectedState({ selectedOptions, filters }: { selectedOptions: any; filters: any }) {
    if (!selectedOptions || !selectedOptions.length) {
      return filters;
    }
    const options = filters.map((item: any) => {
      const selectItem = selectedOptions.find((x: any) => x.id === item.id);
      if (selectItem) {
        return selectItem;
      }
      return item;
    });
    return options;
  }

  private slected({ selectedOptions, filters }: { selectedOptions: any; filters: any }) {
    return filters.map((item: any) => {
      item.checked = false;
      const checked = selectedOptions.find((x: any) => x.id === item.id);
      if (checked) {
        item.checked = true;
      }
      return item;
    });
  }

  private get filtersDatetime() {
    return monthRange({ startDate: new Date(), months: 6 }).map(item => {
      return {
        idString: item.datetime,
        name: item.name,
      };
    });
  }

  private async onSearchText() {
    const searchText = !this.searchText || this.searchText.length <= 3 ? '' : this.searchText;
    const response = await coursesByFilters({ params: this.query, searchText });
    this.$emit('submit-object-filters', response.courses);
  }
}
