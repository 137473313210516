import ScheduleHome from '@/views/schedule/Home.vue';
import ScheduleSingle from '@/views/schedule/Single.vue';

export const ScheduleRoute = [
  {
    name: 'ScheduleHome',
    path: '/:lang/schedule',
    component: ScheduleHome,
  },
  {
    name: 'ScheduleSingle',
    path: '/:lang/schedule/single-:slug/:id',
    component: ScheduleSingle,
  },
];
