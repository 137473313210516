













































const getPosition = (element) => (element.getBoundingClientRect());

import { Component, Prop, Vue } from 'vue-property-decorator';

import FeedbackCarousel from '@/components/FeedbackCarousel.vue';

@Component({
  components: {
    FeedbackCarousel,
  }
})

export default class AnchorTabs extends Vue {
  @Prop({ required: false, default: () => [] }) tabs?: Array<[]>;

  private activeTabsContent = 0;

  private isActiveTab(contentItem: number): boolean {
    return this.activeTabsContent === contentItem;
  }

  private navigateAnchor(idx: number) {
    const targetEl = document.getElementById(`single-tab-${idx}`);
    const yOffset = -90; 
    const topPosition = getPosition(targetEl).top + window.pageYOffset + yOffset;
    window.scroll({
      top: topPosition,
      left: 0,
      behavior: 'smooth'
    })
    this.setActiveTab(idx);
  }

  private setActiveTab(contentItem: number): void {
    this.activeTabsContent = contentItem;
  }
}
