export type IEvents = {
  meta: {
    total_count: number;
  };
  items: IEventsSingle[];
};

export enum IEventStatus {
  CONFIRMED = 'confirmed',
  SCHEDULED = 'scheduled',
}

export type IEventsFiltersSingle = {
  id?: number;
  idString?: string;
  name?: string;
  title?: string;
};

export type IEventsFilters = {
  categories: IEventsFiltersSingle[];
  courses: IEventsFiltersSingle[];
  languages: IEventsFiltersSingle[];
  locations: IEventsFiltersSingle[];
  training_formats: IEventsFiltersSingle[];
  datetime: IEventsFiltersSingle[];
};

export type IEventLanguage = {
  id: number;
  meta: {
    type: string;
    detail_url: string;
  };
  name: string;
};

export type IEventLocation = {
  city: string;
  code: string;
  country: string;
  id: number;
  name: string;
  street: string;
};

export type IEventsSingle = {
  id: number;
  meta: {
    type: string;
    detail_url: string;
    html_url: string;
    slug: string;
    show_in_menus: boolean;
    seo_title: string;
    search_description: string;
    first_published_at: Date;
    locale: string;
  };
  title: string;
  course: {
    id: number;
    meta: {
      type: string;
      detail_url: string;
    };
    title: string;
  };
  start_date: Date;
  end_date: Date;
  languages: IEventLanguage[];
  location: IEventLocation;
  status: IEventStatus;
  training_format: any;
  what_you_need_to_know: string;
};
