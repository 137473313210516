










import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class HamburgerButton extends Vue {
@Prop({required: false , default: false }) isClosed?: boolean
}
